<template>
    <div class="list-group-item">
        <div>
            <div v-if="isSortable" class="drag-handle">
                <img src="/images/draggable-icon.svg" alt="draggable-icon" />
            </div>
        </div>

        <asset-thumb :type="media.type" :thumb="thumb" :progress="progress" :status="status" @click="onMediaClick" />

        <asset-title
            class="title-caption-wrap"
            :title="title"
            :caption="caption"
            :caption-placeholder="captionPlaceholder"
            :readonly="!isReady"
            @change="onAssetChange"
            @close="onAssetSaveChanges"
        />

        <asset-format
            :type="media.type"
            :videoType="media.asset.format"
            @change="onAssetChange"
            @save="onAssetSaveChanges"
        />

        <asset-options v-if="isReady" :asset="media" @delete="onDeleteMedia" />
    </div>
</template>

<script>
import { get, isEmpty } from 'utils/lodash';
import { assetType } from 'utils/helpers';
import AssetTitle from './title';
import AssetThumb from './thumb';
import AssetFormat from './format';
import AssetOptions from './options';
import Echo from 'services/echo';
import { mapActions, mapGetters } from 'vuex';
export default {
    components: {
        AssetThumb,
        AssetTitle,
        AssetFormat,
        AssetOptions,
    },
    props: {
        media: {
            type: Object,
            default: () => {},
        },
        gallery: {
            type: [Object, Array],
            default: () => {},
        },
        sortable: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        changes: {},
        deleting: false,
    }),
    computed: {
        ...mapGetters('map', ['id']),
        status() {
            return get(this.media, 'asset.status');
        },
        thumb: function () {
            if (this.status != 'ready') {
                return '';
            }

            if (this.media.type == 'video') {
                return this.media.asset.poster.animated + '?width=160';
            } else {
                return this.media.asset.url + '?w=160';
            }
        },
        progress() {
            return get(this.media, 'progress', null);
        },
        hasGalleryChannel() {
            return !isEmpty(get(this.gallery, 'broadcast_channel'));
        },
        title() {
            return this.deleting ? 'Deleting...' : get(this.media, 'asset.title', '');
        },
        caption() {
            return this.deleting ? 'Please Wait.' : get(this.media, 'asset.caption', '');
        },
        captionPlaceholder() {
            return this.media.type == 'video' ? 'Enter a caption...' : 'Enter a caption or url...';
        },
        isUploaded() {
            return get(this.media, 'id', false);
        },
        isReady() {
            return this.status == 'ready';
        },
        isSortable() {
            return this.sortable && this.isReady;
        },
    },
    watch: {
        gallery: {
            immediate: true,
            handler: 'onGalleryChange',
        },
    },
    beforeDestroy() {
        if (this.hasGalleryChannel) {
            Echo.private(this.gallery.broadcast_channel)
                .stopListening(`.media.updated.${this.media.id}`)
                .stopListening(`.media.deleted.${this.media.id}`);
        }
    },
    methods: {
        ...mapActions('assets', ['delete', 'update']),
        onGalleryChange() {
            if (this.hasGalleryChannel) {
                Echo.private(this.gallery.broadcast_channel)
                    .listen(`.media.updated.${this.media.id}`, (asset) => this.$emit('updated', asset))
                    .listen(`.media.deleted.${this.media.id}`, (asset) => this.$emit('deleted', asset));
            }
        },
        async onDeleteMedia(asset) {
            this.media.asset.title = 'Deleting...';
            this.media.asset.caption = 'Please Wait.';
            await this.delete({
                map: this.id,
                gallery: this.gallery.id,
                type: assetType(asset.type),
                media: asset.id,
            });

            this.$emit('deleted', asset);
        },
        onAssetChange(changes) {
            this.changes = { ...this.changes, ...changes };
        },
        onAssetSaveChanges() {
            if (isEmpty(this.changes)) {
                return;
            }
            this.update({
                map: this.id,
                gallery: this.gallery.id,
                type: assetType(this.media.type),
                media: this.media.id,
                payload: this.changes,
            });
        },
        onMediaClick() {
            if (this.status == 'ready') {
                this.$emit('click', this.media);
            }
        },
    },
};
</script>
