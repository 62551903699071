<template>
    <div class="filter-field">
        <component
            :is="component"
            :field="filterField"
            :value="value"
            :layer="layer"
            v-model="value"
            @change="onChange"
            @lock="onToggleLock"
            @hide="onToggleVisibility"
        />
    </div>
</template>

<script>
import { get } from '@/utils/lodash';
export default {
    props: {
        layer: {
            type: Object,
            default: () => {},
        },
        field: {
            type: Object,
            required: true,
        },
        filters: {
            type: [Object, Array],
            required: true,
        },
    },
    data: () => ({
        fieldMap: {
            segment: 'button-group',
            input: 'textfield',
            dropdown: 'select',
            range: 'range',
            switch: 'switch',
            'switch-group': 'switch-group',
        },
    }),
    computed: {
        isBoolean() {
            return get(this.field, 'value_type') === 'boolean';
        },
        value: {
            get() {
                let value = this.filters[this.field.id];

                if (this.field.filters.multiselect && typeof value === 'string') {
                    value = value.split(',');
                }

                if (value) {
                    return value;
                }

                return this.isBoolean ? false : null;
            },
            set(value) {
                this.onChange(value);
            },
        },
        filterField() {
            return {
                title: this.field.title,
                id: this.field.id,
                slug: this.field.slug,
                is_multiselect: this.field.filters.multiselect,
                options: this.field.filters.options,
                filters: this.field.filters,
                extra: this.field.extra,
            };
        },
        component() {
            const fieldName = this.fieldMap[this.field.filters.type.key] ?? this.field.filters.type.key;

            if (!fieldName) {
                return;
            }

            return require(`@/components/fields/types/${fieldName}.vue`).default;
        },
    },
    methods: {
        onChange(value) {
            this.$emit('change', { field: this.field, value: value });
        },
        onToggleLock(field, value) {
            this.$emit('lock', field, value);
        },
        onToggleVisibility(field, value) {
            this.$emit('hide', field, value);
        },
    },
};
</script>

<style>
.filter-field label.form__label {
    font-weight: bold;
}
</style>
