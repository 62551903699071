<template>
    <div>
        <layer-icon :layer="layer" />
        <div class="expanded-subheader-wrapper">
            <p class="expanded-subheader">Point Annotations</p>
            <div>
                <r-icon
                    v-if="hasCopy"
                    icon-style="fal"
                    name="paste"
                    size="lg"
                    @click="onPasteAnnotation"
                    class="clickable mr-2"
                />
                <r-icon name="plus-circle" icon-style="fal" size="lg" class="mr-2.5" @click="onAddAnnotation" />
            </div>
        </div>
        <annotations :layer="layer" />
        <annotation-form
            v-if="isNewAnnotationFormVisible"
            creating
            :layer="layer"
            @back="onCancelAddAnnotation"
            v-bind:index="index"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get, map } from 'utils/lodash';
import LayerIcon from './components/icon/icon';
import AnnotationForm from './components/annotations/form';
import Annotations from './components/annotations/list';
export default {
    components: {
        Annotations,
        AnnotationForm,
        LayerIcon,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        isNewAnnotationFormVisible: false,
        index: null,
    }),
    computed: {
        ...mapGetters('annotation', ['hasCopy', 'annotationCopy']),
        hasAnnotations() {
            return get(this.layer, 'annotations') !== undefined;
        },
        annotations() {
            return get(this.layer, 'annotations', []);
        },
        annotation() {
            return this.annotations[this.index];
        },
        order() {
            return this.index + 1;
        },
    },
    methods: {
        ...mapActions('map', ['updateLayer', 'addAnnotation', 'removeAnnotation', 'saveMapWithoutOverwriting']),
        ...mapActions('gallery', { deleteGallery: 'delete' }),
        ...mapActions('annotation', ['clearAnnotation']),
        ...mapGetters('map', ['id']),
        onAddAnnotation() {
            if (!this.hasAnnotations) {
                this.updateLayer({
                    ...this.layer,
                    annotations: [this.makeAnnotation()],
                });
            } else {
                this.addAnnotation({
                    layer: this.layer,
                    annotation: this.makeAnnotation(),
                });
            }

            this.isNewAnnotationFormVisible = true;
        },
        makeAnnotation() {
            this.index = this.annotations.length;
            return {
                name: '',
                place: '',
                location: '',
                description: '',
                url: '',
                order: this.order,
            };
        },
        onCancelAddAnnotation() {
            // remove this annotation & it's gallery if it's blank
            let nonEmptyFields = [this.annotation.name, this.annotation.location];

            if (!nonEmptyFields.filter(Boolean).length) {
                if (this.annotation.gallery != null) {
                    this.deleteGallery({
                        gallery: this.annotation.gallery.id,
                    });
                }

                this.removeAnnotation({ layer: this.layer, index: this.index });
            }

            this.isNewAnnotationFormVisible = false;
        },
        onPasteAnnotation() {
            this.addAnnotation({
                layer: this.layer,
                annotation: this.annotationCopy,
                addToBeginning: this.hasAnnotations,
            });
            this.sortAnnotations(this.annotationCopy);
            this.saveMapWithoutOverwriting();
            this.clearAnnotation();
            this.$success('Annotation saved to layer');
        },
        sortAnnotations(newAnnotation) {
            let i = 1;
            let annotations = [];

            annotations.push(newAnnotation);
            map(this.annotations, (item) => {
                annotations.push({ ...item, order: ++i });
            });

            this.updateLayer({
                ...this.layer,
                annotations: annotations,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.expanded-subheader-wrapper {
    margin-left: 6px;
}
</style>
