<template>
    <r-modal open title="Rename Layer" @close="onClose">
        <form @submit.prevent>
            <r-input-group label="Name" class="mb-6">
                <r-input id="name" name="name" v-model="name" :class="{ invalid: inputIsInvalid }" />
            </r-input-group>
            <r-button :loading="loading" @click="onSave">Save</r-button>
        </form>
    </r-modal>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        inputIsInvalid: false,
        name: null,
    }),
    watch: {
        layer: {
            immediate: true,
            deep: true,
            handler: function () {
                this.name = this.layer.name;
            },
        },
    },
    methods: {
        ...mapActions('map', ['saveLayer']),
        onClose() {
            this.$emit('close');
        },
        async onSave() {
            if (!this.name) {
                this.inputIsInvalid = true;

                return;
            }

            this.loading = true;

            await this.saveLayer({ ...this.layer, name: this.name });

            this.$emit('renamed');
            this.loading = false;
        },
    },
};
</script>
