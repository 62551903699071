<template>
    <span />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    components: {},
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('categories', ['findCategoryBySlug']),
    },
    created() {
        this.$events.$emit('layer:data-content', this.layer);
    },
};
</script>
