<template>
    <div class="subpanel" @click="onEdit">
        <div class="drag-handle">
            <img src="/images/draggable-icon.svg" alt="upload-cloud-icon" />
        </div>
        <p class="cursor-pointer name truncate">{{ annotation.name }}</p>
        <r-icon class="close-icon" name="times-circle" icon-style="fal" size="lg" @click.stop="onRemove" />
    </div>
</template>

<script>
export default {
    props: {
        index: {
            type: [String, Number],
            required: true,
        },
        layer: {
            type: Object,
            required: true,
        },
        annotation: {
            type: Object,
            required: true,
        },
    },
    methods: {
        onEdit() {
            this.$events.$emit('layer:annotation:select', this.annotation, this.layer);
            this.$emit('edit', this.annotation, this.index);
        },
        onRemove() {
            this.$emit('remove', this.annotation, this.index);
        },
    },
};
</script>

<style lang="scss" scoped>
.subpanel {
    background-color: white;
    border-radius: 3px;
    margin-bottom: 9px !important;

    .name {
        width: 285px;
        padding: 0 55px 0 5px;
        font-size: 12px;
    }

    .close-icon {
        margin-right: 10px;
        cursor: pointer;
    }
}
</style>
