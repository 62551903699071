<template>
    <span />
</template>

<script>
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
};
</script>
