import pusher from 'pusher-js';
import Echo from 'laravel-echo';

const client = new pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    authEndpoint: process.env.VUE_APP_RELOCITY_PLATFORM_URL + '/broadcasting/auth',
    auth: {
        headers: {
            Authorization: 'Bearer ' + localStorage.relocity_access_token,
        },
    },
    encrypted: true,
});

const echo = new Echo({ broadcaster: 'pusher', client: client });

export default echo;
