<template>
    <div class="format">
        <r-select v-if="isVideo" id="format" name="format" v-model="videoType" :options="types" @change="onChange" />
    </div>
</template>

<script>
import { VIDEO_TYPES } from 'config';
import { startsWith } from 'utils/lodash';
export default {
    props: {
        type: {
            type: [Object, String],
            required: true,
        },
        videoType: {
            type: String,
            required: true,
            default: 'standard',
        },
    },
    computed: {
        isVideo() {
            return startsWith(this.type, 'video');
        },
        types() {
            return VIDEO_TYPES;
        },
    },
    methods: {
        onChange(newVideoType) {
            this.$emit('change', { format: newVideoType });
            this.$emit('save');
        },
    },
};
</script>
