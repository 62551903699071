<template>
    <div class="flex flex-row space-x-2">
        <div class="w-1/2">
            <pre class="block whitespace-pre overflow-x-scroll h-80 rounded-lg bg-gray-400 p-2">{{ pretty }}</pre>
        </div>
        <div class="w-1/2">
            <textarea
                ref="myinput"
                readonly
                :value="raw"
                class="w-full h-80 border border-primary rounded-lg p-2"
                @focus="$event.target.select()"
            />
        </div>
    </div>
</template>

<script>
import { get } from '@/utils/lodash';
import Map from 'models/Map';
import { mapGetters } from 'vuex';
export default {
    data: () => ({
        json: '',
    }),
    computed: {
        ...mapGetters('map', ['user', 'id']),
        pretty() {
            return JSON.stringify(this.json, null, 2);
        },
        raw() {
            return JSON.stringify(this.json);
        },
    },
    async created() {
        try {
            const { data } = await Map.export(this.user, this.id);
            this.json = data;
        } catch ({ data: { errors } }) {
            this.json = get(errors, '0.detail');
        }
    },
};
</script>
