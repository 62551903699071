<template>
    <r-modal open class="grey gallery-modal large" :class="{ 'z-100': isMobile }" :dismissible="false">
        <div class="media-preview" :class="{ 'full-width': isMobile }">
            <media-image-preview v-if="isImage" :media="media" @close="onClose" />
            <media-video-preview v-else-if="isVideo" :media="media" @close="onClose" @playchange="onPlayChange" />
            <transition name="fade">
                <title-and-caption
                    v-if="media.asset.title && !hideTitleAndCaption"
                    :hideCaption="hasURL"
                    :media="media"
                />
            </transition>
            <button class="visit-btn" v-if="hasURL" @click="visitURL">visit</button>
        </div>
    </r-modal>
</template>

<script>
import MediaImagePreview from './preview/image';
import MediaVideoPreview from './preview/video';
import TitleAndCaption from './preview/title-and-caption';
import { mq } from 'mixins';
export default {
    mixins: [mq],
    components: {
        MediaImagePreview,
        MediaVideoPreview,
        TitleAndCaption,
    },
    data: () => ({
        hideTitleAndCaption: false,
    }),
    watch: {
        media: function () {
            this.hideTitleAndCaption = false;
        },
    },
    props: {
        media: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isImage() {
            return this.media.type == 'photo';
        },
        isVideo() {
            return this.media.type == 'video';
        },
        url() {
            let match = this.media.asset.caption?.match(/https?:\/\/[^\s]+/);
            return match?.length > 0 ? match[0] : null;
        },
        hasURL() {
            return this.url != null;
        },
    },
    methods: {
        onClose() {
            this.$emit('close');
        },
        onPrev() {
            this.$emit('prev');
        },
        onNext() {
            this.$emit('next');
        },
        onPlayChange(isPlaying) {
            this.hideTitleAndCaption = isPlaying;
        },
        visitURL() {
            window.open(this.url);
        },
    },
};
</script>

<style lang="scss" scoped>
.media-preview {
    max-width: 750px;
    max-height: 730px;

    &.full-width {
        max-width: 100%;
    }
}
</style>

<style lang="scss">
.z-100 {
    z-index: 100 !important;
}
.gallery-modal {
    width: auto !important;
    border: none;
    padding: 17px;
    padding-top: 3rem;

    div.media-preview {
        height: 100%;
        position: relative;
    }

    .visit-btn {
        color: white;
        font-size: 34px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        z-index: 1;
        padding: 5px 60px;
        border-radius: 30px;
        border: 2px solid white;

        &:hover {
            background-color: #ffffff4d;
        }
    }
}
</style>
