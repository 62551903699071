<template>
    <div>
        <r-draggable v-model="annotations" @change="onSort">
            <annotation
                v-for="(annotation, index) in annotations"
                :key="index"
                :index="index"
                :layer="layer"
                :annotation="annotation"
                @edit="onEdit"
                @remove="onRemove"
            />
        </r-draggable>

        <annotation-form
            v-if="isEditing"
            :layer="layer"
            :index="index"
            :annotation="annotation"
            @back="onCancelEdit"
            @copy="onCopy"
        />
    </div>
</template>

<script>
import { get, sortBy, cloneDeep, map } from 'utils/lodash';
import Annotation from './annotation';
import AnnotationForm from './form';
import { mapActions } from 'vuex';
import Const from 'components/layers/types/area_tour/const';
import MapService from 'services/mapservice';
export default {
    components: {
        Annotation,
        AnnotationForm,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        isEditing: false,
        annotation: null,
        index: null,
    }),
    computed: {
        annotations() {
            return sortBy(get(this.layer, 'annotations', []), ['order']);
        },
    },
    methods: {
        ...mapActions('map', ['removeAnnotation', 'updateLayer']),
        ...mapActions('annotation', ['copyAnnotation']),
        onEdit(annotation, index) {
            this.annotation = cloneDeep(annotation);
            this.index = index;
            this.isEditing = true;

            MapService.addMarkerFeatureState(
                this.layer.name + Const.AREA_TOUR_SOURCE_ID,
                this.layer.name + Const.AREA_TOUR_LAYER_ID,
                index
            );
        },
        onRemove(annotation, index) {
            this.removeAnnotation({ layer: this.layer, index: index });
        },
        onCancelEdit() {
            this.index = null;
            this.annotation = null;
            this.isEditing = false;

            MapService.removeMarkerFeatureState(
                this.layer.name + Const.AREA_TOUR_SOURCE_ID,
                this.layer.name + Const.AREA_TOUR_LAYER_ID
            );
        },
        onSort(list) {
            let i = 0;
            let annotations = [];
            map(list, (item) => {
                annotations.push({ ...item, order: ++i });
            });

            const geoJson = MapService.geoJsonFromLayerAnnotations(this.layer, annotations);

            this.updateLayer({
                ...this.layer,
                annotations: annotations,
                geoJson: geoJson,
            });
        },
        onCopy() {
            this.copyAnnotation(this.annotation);
            this.$success('Annotation copied');
        },
    },
};
</script>
