<template>
    <span />
</template>

<script>
import { modifyFeatures } from 'utils/helpers';
import http from 'services/axios';
import { forEach, isEmpty } from 'utils/lodash';
import MapService from 'services/mapservice';
import { mapGetters } from 'vuex';
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        mapBox: null,
    }),
    computed: {
        ...mapGetters('map', ['id', 'latitude', 'longitude', 'isStyleReady', 'isChangingProvider']),
        layerId() {
            return this.layer.name + '__SCHOOLS__';
        },
        sourceId() {
            return this.layer.name + '__SCHOOLS_SOURCE__';
        },
        filters() {
            return this.layer?.filters;
        },
        url() {
            let url = new URL(this.layer.url);
            let params = new URLSearchParams();
            params.set('latitude', this.latitude);
            params.set('longitude', this.longitude);
            params.set('radius', this.filters.distance);
            forEach(this.filters.type, (type) => {
                params.append('school_types[]', type);
            });

            if (isEmpty(this.filters.type)) {
                params.set('school_types[]', '');
            }

            if (!isEmpty(this.filters.level)) {
                params.set('school_level', this.filters.level);
            }

            url.search = params.toString();
            return url.toString();
        },
    },
    watch: {
        ['layer.visible']: {
            handler: 'onLayerUpdate',
        },
        filters: {
            deep: true,
            handler: 'onLayerUpdate',
        },
        map: {
            immediate: true,
            handler: function () {
                this.mapBox = this.map;
            },
        },
    },
    created() {
        this.buildLayers();
        this.$events.$on('map:loaded', this.onMapLoaded);
        this.$events.$on('map:updated', this.onMapUpdated);
    },
    mounted() {
        this.$events.$on('layer:updated', this.onLayerUpdated);
    },
    beforeDestroy() {
        if (this.isChangingProvider) {
            return;
        }

        this.$events.$off('map:loaded', this.onMapLoaded);
        this.$events.$off('map:updated', this.onMapUpdated);
        this.$events.$off('layer:updated', this.onLayerUpdated);

        this.reset();
    },
    methods: {
        onLayerUpdated(layer, mapBox) {
            this.mapBox = mapBox;
            if (this.layer.id == layer.id) {
                this.onLayerUpdate();
            }
        },
        onMapLoaded(mapBox) {
            this.mapBox = mapBox;

            // this.buildLayers();
        },
        onMapUpdated() {
            // this.buildLayers();
        },
        onLayerUpdate() {
            if (!this.layer.visible) {
                this.reset();
            } else {
                this.buildLayers();
            }
        },
        async fetch() {
            let response = await http.get(this.url);

            return response.data;
        },
        async getSourceData() {
            let data = await this.fetch();

            return {
                ...data,
                features: modifyFeatures(data?.features, {
                    layerType: 'schools',
                    layer: this.layer,
                }),
            };
        },
        async buildLayers() {
            this.$events.$off('map:styleloaded', this.buildLayers);
            if (!this.isStyleReady) {
                this.$events.$on('map:styleloaded', this.buildLayers);
                return;
            }

            if (this.mapBox.map.getSource(this.sourceId)) {
                return await this.updateSource();
            }

            try {
                let source = this.getSource(await this.getSourceData());
                // Remove ids
                await this.reset();

                this.mapBox.map.addSource(this.sourceId, source);

                let setup = this.getSetup(source);

                this.addMapLayer(setup);
            } catch (e) {
                console.error(e);
            }
        },
        async reset() {
            try {
                if (this.mapBox.map.getLayer(this.layerId)) {
                    await this.mapBox.map.removeLayer(this.layerId);
                }

                if (this.mapBox.map.getSource(this.sourceId)) {
                    await this.mapBox.map.removeSource(this.sourceId);
                }
            } catch (e) {}
        },
        getSource(data) {
            return {
                type: 'geojson',
                data: data,
                clusterRadius: 14,
                cluster: false,
            };
        },
        getSetup() {
            return {
                id: this.layerId,
                type: 'symbol',
                layout: {
                    'icon-image': 'relocity-school-marker',
                    'icon-allow-overlap': true,
                },
                source: this.sourceId,
            };
        },
        addMapLayer(setup) {
            MapService.addLayer(setup);
        },
        async updateSource() {
            try {
                let source = this.getSource(await this.getSourceData());

                this.mapBox.map.getSource(this.sourceId).setData(source.data);
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>
