<template>
    <div class="annotation-panel-outer">
        <div class="annotation-panel">
            <div class="panel-group edit-details annotation-panel-back-btn" @click="onBack">Back</div>
            <div class="panel-group edit-details">
                <div v-if="title" class="annotation-panel-header">
                    {{ title }}
                    <r-icon name="copy" class="clickable float-right" @click="onCopy" />
                </div>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    methods: {
        onBack() {
            this.$emit('back');
        },
        onCopy() {
            this.$emit('copy');
        },
    },
};
</script>
