<template>
    <div class="square-select">
        <r-select
            v-model="level"
            :options="levels"
            track-by="value"
            label="name"
            id="school-levels"
            name="school-levels"
            @change="(value) => (level = value)"
        />
    </div>
</template>

<script>
import { get } from 'utils/lodash';
import { mapActions } from 'vuex';
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        levels: [
            {
                name: 'All',
                value: null,
            },
            {
                name: 'Elementary',
                value: 'elementary',
            },
            {
                name: 'Middle',
                value: 'middle',
            },
            {
                name: 'High',
                value: 'high',
            },
        ],
    }),
    computed: {
        level: {
            get() {
                return get(this.layer, 'filters.level');
            },
            set(value) {
                this.updateLayer({
                    ...this.layer,
                    filters: { ...this.layer.filters, level: value },
                });
            },
        },
    },
    methods: {
        ...mapActions('map', ['updateLayer']),
        onChange(value) {
            this.level = value;
        },
    },
};
</script>
