<template>
    <div class="location">
        <r-autocomplete
            ref="autocomplete"
            :source="source"
            :class="classes"
            :display="({ description }) => description"
            property="data"
            value="description"
            :initial="value"
            @selected="onSelect"
            @clear="onClear"
        />
    </div>
</template>

<script>
import Place from 'models/Place';
import { mapGetters, mapState } from 'vuex';
export default {
    props: {
        classes: {
            type: [String, Array, Object],
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        radius: 40233, // 25 miles
    }),
    computed: {
        ...mapState('map', ['mapCenter', 'mapBounds']),
        ...mapGetters('map', ['latitude', 'longitude', 'boundsRectangle']),
        locationBias() {
            return `rectangle:${this.boundsRectangle}`;
        },
    },
    methods: {
        source(input) {
            return Place.autoCompleteUrl(input, this.mapCenter.lat, this.mapCenter.lng, this.radius, this.locationBias);
        },
        onClear() {
            this.$emit('clear');
        },
        onSelect(destination) {
            Place.get(destination.selectedObject.place_id).then(({ data }) => {
                this.$emit('change', {
                    place: destination.selectedObject.description,
                    coordinate: {
                        latitude: data.geometry.coordinates[1],
                        longitude: data.geometry.coordinates[0],
                    },
                });
            });
        },
    },
};
</script>
<style lang="scss">
.location {
    .autocomplete__box {
        border: 1px solid #e2e8f0 !important;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
        border-radius: 0.25rem !important;
        color: #4a5568;
        width: 100%;
        line-height: 1.25 !important;
        padding: 0.5rem 0.75rem !important;
    }
}
</style>
