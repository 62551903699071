<template>
    <div>
        <p class="expanded-subheader">Filter Schools By:</p>
        <distance :layer="layer" />
        <types :layer="layer" />
        <div class="school-level-input-container">
            <label for="school-level-input">Level {{ layer.filters.level }}</label>
            <level :layer="layer" />
        </div>
    </div>
</template>

<script>
import Level from './components/level';
import Types from './components/types';
import Distance from './components/distance';
export default {
    components: {
        Level,
        Types,
        Distance,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
};
</script>
