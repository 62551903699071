<template>
    <div :class="{ active: active }" @click="onClick">{{ type.name }}</div>
</template>

<script>
import { mapActions } from 'vuex';
import { get, includes, filter, cloneDeep } from 'utils/lodash';
export default {
    props: {
        type: {
            type: Object,
            required: true,
        },
        layer: {
            type: Object,
            required: true,
        },
    },
    computed: {
        types() {
            return get(this.layer, 'filters.type', []);
        },
        active() {
            return includes(this.types, this.type.value);
        },
    },
    methods: {
        ...mapActions('map', ['updateLayer']),
        onClick() {
            if (this.active) {
                let types = filter(this.types, (type) => type != this.type.value);
                this.update(types);
            } else {
                let types = cloneDeep(this.types);
                types.push(this.type.value);

                this.update(types);
            }
        },
        update(types) {
            this.updateLayer({
                ...this.layer,
                filters: { ...this.layer.filters, type: types },
            });
        },
    },
};
</script>
