<template>
    <div>
        <div class="button-group commute-radius-profiles">
            <commute-type v-for="type in types" :key="type" :type="type" :layer="layer" @change="onTypeSelected" />
        </div>
        <p class="expanded-subheader">Move the slider to adjust the minutes for each segment.</p>
        <r-range-slider :start="sliderStart" :id="layer.id" @update="onSliderUpdate" />
    </div>
</template>

<script>
import CommuteType from './components/type';
import { mapActions } from 'vuex';
import { get } from 'utils/lodash';
import { COMMUTE_TYPES } from 'config';
export default {
    components: {
        CommuteType,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        types: COMMUTE_TYPES,
    }),
    computed: {
        sliderStart() {
            return get(this.layer, 'filters.contours_minutes', 0);
        },
    },
    methods: {
        ...mapActions('map', ['updateLayer']),
        onTypeSelected(type) {
            this.updateLayer({
                ...this.layer,
                filters: { ...this.layer.filters, profile: type },
            });
        },
        onSliderUpdate(range) {
            this.updateLayer({
                ...this.layer,
                filters: { ...this.layer.filters, contours_minutes: range },
            });
        },
    },
};
</script>
