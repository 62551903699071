<template>
    <div class="custom-icon-input-container">
        <icon-image :icon="icon" />
        <div class="square-select">
            <label for="custom-icon-input">Icon</label>
            <r-select
                id="custom-icon-input"
                name="custom-icon-input"
                v-model="icon"
                track-by="value"
                label="name"
                :options="icons"
                @change="onChange"
            />
        </div>
    </div>
</template>

<script>
import { get } from 'utils/lodash';
import ICONS from 'utils/area-tour-icons';
import IconImage from './image';
import { mapActions } from 'vuex';
export default {
    components: {
        IconImage,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        icons: ICONS,
    }),
    computed: {
        icon: {
            get() {
                return get(this.layer, 'icon');
            },
            set(value) {
                this.updateLayer({
                    ...this.layer,
                    icon: value,
                });
            },
        },
    },
    methods: {
        ...mapActions('map', ['updateLayer']),
        onChange(value) {
            this.icon = value;
        },
    },
};
</script>
