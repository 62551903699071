<template>
    <r-dropzone
        ref="dropzone"
        :url="url"
        :options="options"
        :accepted="acceptedFiles"
        @filesAdded="onFilesAdded"
        @sending="onSending"
        @thumbnail="onThumbnail"
        @processing="onProcessing"
        @progress="onProgress"
        @success="onSuccess"
        @error="onError"
        @complete="onComplete"
        @queue-complete="onQueueComplete"
    >
        <div class="drop-active">
            <div class="upload-label-wrapper">
                <div class="icon-wrap">
                    <r-icon icon-style="fas" name="cloud-upload" size="2x" />
                </div>
                <span class="label">Drag &amp; Drop to Upload Image or Video</span>
            </div>
        </div>
    </r-dropzone>
</template>

<script>
import { get, forEach, size } from 'utils/lodash';
import http from 'services/axios';
import { PLATFORM_API_URL } from 'config';
import { assetType, getExtension } from 'utils/helpers';
export default {
    data: () => ({
        url: '#URL#',
        acceptedFiles:
            'image/webp,image/jpeg,image/png,video/h264,video/x-m4v,video/mpeg,video/mp4,video/webm,video/quicktime',
        options: {
            method: 'PUT',
            autoProcessQueue: false,
            parallelUploads: 4,
            timeout: 15 * 60 * 1000,
        },
        signedUrls: {},
    }),
    methods: {
        async onFilesAdded(files) {
            let urls = await this.getSignedUrl(files.length);

            this.signedUrls = {};
            await forEach(files, async (file, i) => {
                let signed = urls[i];
                this.$set(this.signedUrls, file.upload.uuid, signed.url);
                file.signed = signed;
                this.onFileChange(file);

                // only call process if we have all the signed url's
                if (size(this.signedUrls) >= files.length) {
                    this.$refs.dropzone.process();
                }
            });
        },
        onProcessing(file) {
            this.$refs.dropzone.setOption('url', file.signed.url);
            this.$refs.dropzone.options.url = file.signed.url;
            this.onFileChange(file);
        },
        onThumbnail(file, dataUrl) {
            file.thumb = dataUrl;
            this.onFileChange(file);
        },
        onSending(file, xhr) {
            let _send = xhr.send;
            xhr.send = () => {
                _send.call(xhr, file);
            };
            this.onFileChange(file);
            this.$emit('sending', file, xhr);
        },
        onSuccess(file, response) {
            this.$emit('success', this.modifyFile(file), response);
        },
        onError(file, message, xhr) {
            this.$emit('error', file, message, xhr);
        },
        onProgress(file, progress, bytesSent) {
            file.progress = progress;
            file.bytesSent = bytesSent;

            this.onFileChange(file);
        },
        onQueueComplete() {
            this.$emit('queue-completed');
        },
        onComplete(file) {
            this.onFileChange(file);
            this.$emit('complete', file);
        },
        onFileChange(file) {
            if (!get(file, 'signed.uuid')) {
                return;
            }
            this.$emit('change', this.modifyFile(file));
        },
        async getSignedUrl(count) {
            let response;
            try {
                response = await http.post(PLATFORM_API_URL + '/api/v1/storage/signed-upload-urls/' + count, {
                    visibility: 'public-read',
                });
            } catch (error) {
                this.$error(`Error preparing upload: ${error}`);
            }

            return response.data.data;
        },
        modifyFile(file) {
            return JSON.parse(
                JSON.stringify({
                    ...file,
                    id: file.signed.uuid,
                    type: file.type,
                    asset: {
                        status: 'uploading',
                    },
                    mediaType: assetType(file.type),
                    extension: getExtension(file.name),
                })
            );
        },
    },
};
</script>

<style lang="scss">
@import 'scss/global/_variables';
@import 'scss/components/_dropzone';
</style>
