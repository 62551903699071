<template>
    <span />
</template>

<script>
import { modifyFeatures } from 'utils/helpers';
import MapService from 'services/mapservice';
import { mapGetters } from 'vuex';
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            layout: {
                'icon-image': this.layer.icon,
                'icon-allow-overlap': true,
            },
        };
    },
    watch: {
        jsonSource: 'onJsonSourceChange',
    },
    computed: {
        ...mapGetters('map', ['isChangingProvider']),
        layerId() {
            return this.layer.name + '__CUSTOM__';
        },
        sourceId() {
            return this.layer.name + '__CUSTOM_SOURCE__';
        },
        jsonSource() {
            return {
                ...this.layer.geoJson,
                features: modifyFeatures(this.layer.geoJson?.features, {
                    layerType: 'custom',
                    layer: this.layer,
                }),
            };
        },
    },
    created() {
        this.createLayer();
    },
    beforeDestroy() {
        if (this.isChangingProvider) {
            return;
        }

        this.removeLayer();
    },
    methods: {
        async onJsonSourceChange() {
            await this.removeLayer();
            await this.createLayer();
        },
        async createLayer() {
            await MapService.addClusteredFeatureCollection(this.sourceId, this.jsonSource);
            MapService.addClusterCircleLayer(this.sourceId, this.layerId, this.layer.icon);
            MapService.addMarkerCircleLayer(this.sourceId, this.layerId, this.layer.icon);
            MapService.addMarkerCircleRecommendedIconLayer(this.sourceId, this.layerId, this.icon);
            MapService.addMarkerCircleFavoriteIconLayer(this.sourceId, this.layerId, this.icon);
        },
        async removeLayer() {
            await MapService.removeClusterCircleLayer(this.layerId);
            await MapService.removeMarkerCircleLayer(this.layerId);
            await MapService.removeMarkerCircleRecommendedIconLayer(this.layerId);
            await MapService.removeMarkerCircleFavoriteIconLayer(this.layerId);
            await MapService.removeSource(this.sourceId);
        },
    },
};
</script>
