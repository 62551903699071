<template>
    <div class="button-group school-type-input-container">
        <type v-for="type in types" :key="type.value" :type="type" :layer="layer" />
    </div>
</template>

<script>
import Type from './type';
export default {
    components: {
        Type,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        types: [
            {
                name: 'Public',
                value: 'public',
            },
            {
                name: 'Private',
                value: 'private',
            },
            {
                name: 'Charter',
                value: 'charter',
            },
        ],
    }),
};
</script>
