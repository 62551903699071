<script>
export default {
    props: {
        confirmButtonText: {
            type: String,
            default: 'Confirm',
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        },
        open: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Delete Confirm',
        },
    },
    methods: {
        onConfirm() {
            this.$emit('confirm');
        },
        onCancel() {
            this.$emit('cancel');
        },
    },
};
</script>

<template>
    <r-modal :open="open" :dismissible="false" v-on="$listeners">
        <template #header>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ title }}
            </h3>
        </template>
        <template #icon>
            <div
                class="mx-auto flex-shrink-0 flex items-center h-10 w-10 justify-center rounded-full bg-danger sm:mx-0"
            >
                <r-icon name="exclamation-triangle" size="sm" variant="white" />
            </div>
        </template>
        <template #footer>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <r-button variant="danger" @click="onConfirm">
                        {{ confirmButtonText }}
                    </r-button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <r-button variant="white" @click="onCancel">
                        {{ cancelButtonText }}
                    </r-button>
                </span>
            </div>
        </template>
        <div class="mt-2 max-w-xl text-sm leading-5 text-gray-800">
            <slot> Are you sure you want to delete this item? This action cannot be undone. </slot>
        </div>
    </r-modal>
</template>
