<template>
    <div class="subpanel" @click="onEdit">
        <div class="drag-handle">
            <img class="draggable-icon" src="/images/draggable-icon.svg" alt="upload-cloud-icon" />
        </div>
        <position :icon="layer.icon" :position="annotation.order" />
        <p class="cursor-pointer name truncate">{{ annotation.name }}</p>
        <r-icon class="close-icon" name="times-circle" icon-style="fal" size="lg" @click.stop="onRemove" />
    </div>
</template>

<script>
import Position from 'components/layers/types/area_tour/components/position';
export default {
    components: {
        Position,
    },
    props: {
        index: {
            type: [String, Number],
            required: true,
        },
        layer: {
            type: Object,
            required: true,
        },
        annotation: {
            type: Object,
            required: true,
        },
    },
    methods: {
        onEdit() {
            this.$events.$emit('layer:annotation:select', this.annotation, this.layer);
            this.$emit('edit', this.annotation, this.index);
        },
        onRemove() {
            this.$emit('remove', this.annotation, this.index);
        },
    },
};
</script>

<style lang="scss" scoped>
.subpanel {
    background-color: white;
    border-radius: 3px;
    margin-bottom: 9px !important;

    .name {
        width: 285px;
        padding: 0 55px 0 5px;
        font-size: 12px;
    }

    .close-icon {
        margin-right: 10px;
        cursor: pointer;
    }

    .drag-handle {
        margin-left: 5px;

        .draggable-icon {
            vertical-align: middle;
        }
    }
}
</style>
