<template>
    <div class="panel-item" @click="onEdit">
        <div class="panel-item-header-wrapper">
            <div v-if="isSortableVisible" class="drag-handle">
                <img src="/images/draggable-icon.svg" alt="upload-cloud-icon" />
            </div>
            <visibility :layer="layer" />
            <h3 class="panel-item-header truncate mr-3">{{ layer.name }}</h3>

            <div class="menu-icon-wrapper" v-if="!isOpen">
                <button class="menu-icon" />
                <div class="sub-menu left">
                    <button class="sub-menu-option" @click.stop="onRename">Rename</button>
                    <button class="sub-menu-option" @click.stop="onCopy">Copy</button>
                    <button class="sub-menu-option" @click.stop="onEdit">Edit</button>
                    <button class="sub-menu-option" @click.stop="onDelete">Delete</button>
                </div>
            </div>

            <a class="text-only-btn" v-if="showButtons" @click.stop="onCancel">Cancel</a>
            <r-button v-if="showButtons" :loading="isLoading" @click.stop="onSave">Save</r-button>
        </div>

        <component v-if="isOpen" :is="panel" :layer="layer" :map="map" class="expanded-state" />
        <component v-else :is="summary" :layer="layer" :map="map" class="panel-item-subheader" />
        <component v-if="isVisible && isStyleReady" :is="mapComponent" :layer="layer" :map="map" />
        <component v-if="searchComponent != null" :is="searchComponent" :layer="layer" :map="map" />
    </div>
</template>

<script>
import { get } from 'utils/lodash';
import Visibility from './visibility';
import { mapActions, mapGetters } from 'vuex';
export default {
    components: {
        Visibility,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
        sortable: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isOpen: false,
        isLoading: false,
    }),
    computed: {
        ...mapGetters('map', ['isStyleReady']),
        ...mapGetters('layers', ['editing', 'isEditingLayer']),
        showButtons() {
            // don't show cancel/save buttons for data content -- it has an editing modal
            return this.isOpen && this.layer.type != 'data_content';
        },
        summary() {
            return require(`@/components/layers/types/${this.layer.type}/summary.vue`).default;
        },
        panel() {
            return require(`@/components/layers/types/${this.layer.type}/panel.vue`).default;
        },
        mapComponent() {
            return require(`@/components/layers/types/${this.layer.type}/map.vue`).default;
        },
        searchComponent() {
            try {
                return require(`@/components/layers/types/${this.layer.type}/search.vue`).default;
            } catch (e) {
                return null;
            }
        },
        isVisible() {
            return get(this.layer, 'visible', true);
        },
        isSortableVisible() {
            return this.sortable;
        },
    },
    watch: {
        isOpen() {
            this.$emit('editing', this.isOpen);
        },
        editing: {
            immediate: true,
            handler: 'onIsEditing',
        },
    },
    methods: {
        ...mapActions('map', ['saveMap', 'saveLayer']),
        ...mapActions('gallery', ['deleteFlaggedGalleries']),
        ...mapActions('layers', ['copyLayer', 'clearEditing']),
        onIsEditing() {
            if (this.isEditingLayer(this.layer.id)) {
                this.isOpen = true;
                this.clearEditing();
            } else {
                this.isOpen = false;
            }
        },
        onRename() {
            this.$emit('rename', this.layer);
        },
        onEdit() {
            this.isOpen = true;
        },
        onDelete() {
            this.$emit('delete', this.layer);
        },
        onCancel() {
            this.isOpen = false;
        },
        onCopy() {
            this.copyLayer(this.layer);
            this.$success('Layer Copied.');
        },
        async onSave() {
            this.isLoading = true;
            try {
                await this.saveLayer(this.layer);
                await this.deleteFlaggedGalleries();
                this.isOpen = false;
            } catch (e) {
                this.$error('Failed Saving Map Changes.');
            }

            this.isLoading = false;
        },
    },
};
</script>

<style lang="scss">
@import 'scss/components/_layers';
</style>

<style scoped>
.drag-handle {
    margin-left: -15px;
}
.text-only-btn {
    cursor: pointer;
}
</style>
