<template>
    <div>
        <div v-if="isFeatureEnabled" class="my-4">
            <h3 class="text-lg leading-6 text-primary mb-3">Layer Filters</h3>
            <p class="text-sm text-secondary">
                Configure the layer filters with the option to lock and hide each individual filter. When a filter is
                locked, the client will not be able change the filter and when the filter is hidden, the client will not
                see the filter.
            </p>
        </div>
        <div class="flex flex-col space-y-4">
            <div v-for="section in sectionsWithFilters" :key="section.id">
                <h3 class="text-lg leading-6 text-primary mb-3 mt-4" v-text="section.title" />
                <filter-section
                    :section="section"
                    :layer="layer"
                    :filters="filters"
                    @change="onFilterChange"
                    @lock="onToggleLock"
                    @hide="onToggleVisibility"
                />
            </div>
        </div>
    </div>
</template>

<script>
import FilterSection from './section.vue';
export default {
    components: {
        FilterSection,
    },
    props: {
        layer: {
            type: Object,
            default: () => {},
        },
        category: {
            type: Object,
            required: true,
        },
        filters: {
            type: [Object, Array],
            required: true,
        },
        providers: {
            type: Array,
            required: true,
        },
    },
    computed: {
        sections() {
            return this.category.sections;
        },
        sectionsWithFilters() {
            return this.sections.filter((section) => section.fields.filter((field) => field.is_filterable).length > 0);
        },
        firstSection() {
            return this.sections[0];
        },
        isFeatureEnabled() {
            return process.env.VUE_APP_FEATURE_ENABLE_LOCK_HIDE === 'true';
        },
    },
    methods: {
        onFilterChange(args) {
            this.$emit('filters-change', args);
        },
        onToggleLock(field, value) {
            this.$emit('lock-change', field, value);
        },
        onToggleVisibility(field, value) {
            this.$emit('hide-change', field, value);
        },
    },
};
</script>
