<template>
    <r-ui-select
        :id="id"
        :name="name"
        v-model="selectedProviders"
        :options="providerOptions"
        track-by="id"
        label="name"
        empty
        multiple
        @input="onChange"
    />
</template>

<script>
import { isEmpty, includes, filter } from 'utils/lodash';
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {
        id: {
            type: String,
            default: 'provider_id',
        },
        name: {
            type: String,
            default: 'provider_id',
        },
        value: {
            type: Array,
            required: true,
        },
        providers: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapGetters('providers', { allProviders: 'providers' }),
        providerOptions() {
            return !isEmpty(this.providers) ? this.providers : this.allProviders;
        },
        selectedProviders: {
            get() {
                return filter(this.providerOptions, (provider) => includes(this.value, provider.id));
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    created() {
        if (isEmpty(this.allProviders)) {
            this.all();
        }
    },
    methods: {
        ...mapActions('providers', ['all']),
        onChange(value) {
            this.$emit('change', value);
        },
    },
};
</script>
