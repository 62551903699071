<template>
    <div class="square-select">
        <r-select
            v-model="distance"
            :options="distances"
            id="school-distance"
            name="school-distance"
            class=""
            @change="onChange"
        />
    </div>
</template>

<script>
import { get, map } from 'utils/lodash';
import { mapActions } from 'vuex';
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        distanceOptions: ['1', '5', '10', '15', '30'],
    }),
    computed: {
        distance: {
            get() {
                return get(this.layer, 'filters.distance');
            },
            set(value) {
                this.updateLayer({
                    ...this.layer,
                    filters: { ...this.layer.filters, distance: value },
                });
            },
        },
        distances() {
            return map(this.distanceOptions, (distance) => {
                return { key: distance, title: `${distance} Miles Distance` };
            });
        },
    },
    methods: {
        ...mapActions('map', ['updateLayer']),
        onChange(value) {
            this.distance = value;
        },
    },
};
</script>
