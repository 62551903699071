<template>
    <r-input-group :label="{ label: field.title, for: field.slug }" class="tags-input">
        <r-tags :id="field.slug" :name="field.slug" v-model="tag" :tags="tags" class="tags" @tags-changed="onChange" />
    </r-input-group>
</template>

<script>
export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        tag: '',
        tags: [],
    }),
    methods: {
        onChange(tags) {
            this.$emit('change', tags);
        },
    },
};
</script>

<style lang="scss">
.tags-input {
    .tags {
        padding: 4px;
        .ti-input {
            border: 0;
            padding: 0;
        }
        li.ti-tag {
            background-color: black;
        }
    }
}
</style>
