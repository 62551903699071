<template>
    <r-input-group :label="{ label: field.title, for: field.slug }">
        <r-datetime-picker @input="onChange" />
    </r-input-group>
</template>

<script>
export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
    methods: {
        onChange(value) {
            this.$emit('change', value);
        },
    },
};
</script>
