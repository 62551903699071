<template>
    <r-input-group>
        <template #label>
            <slot name="label">
                <div class="flex flex-row space-x-2 justify-center items-center">
                    <label :label="field.title" :for="field.slug" class="form__label text-primary">{{
                        field.title
                    }}</label>
                    <field-actions :field="field" :layer="layer" v-on="$listeners" />
                </div>
            </slot>
        </template>
        <r-input
            v-bind="$attrs"
            :placeholder="placeholder"
            :id="field.slug"
            :name="field.slug"
            :value="value"
            @input="onChange"
        />
    </r-input-group>
</template>

<script>
import { debounce, includes, get } from 'utils/lodash';
import FieldActions from '../actions.vue';
export default {
    components: {
        FieldActions,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
        layer: {
            type: Object,
            default: () => {},
        },
        value: {
            type: String,
            default: '',
        },
    },
    computed: {
        isLocked() {
            return includes(this.layer.filters?.locked_fields, this.field.id);
        },
        placeholder() {
            return get(this.field, 'extra.filter_helper_text');
        },
    },
    methods: {
        onChange: debounce(function (value) {
            this.$emit('change', value);
        }, 200),
    },
};
</script>
