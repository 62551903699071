<template>
    <r-autocomplete
        :source="destinationSource"
        property="features"
        value="center"
        :initial="initial"
        :display="({ place_name }) => place_name"
        @selected="onChange"
        @clear="onClear"
    />
</template>

<script>
import { MAPBOX_ACCESS_TOKEN } from 'config';
export default {
    props: {
        initial: {
            type: String,
            default: '',
        },
    },
    methods: {
        destinationSource(input) {
            return `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?autocomplete=true&limit=10&access_token=${MAPBOX_ACCESS_TOKEN}`;
        },
        onChange(destination) {
            this.$emit('change', destination);
        },
        onClear() {
            this.$emit('clear');
        },
    },
};
</script>
