<template>
    <r-polygon
        ref="polygon"
        height="100%"
        class="rounded-lg shadow-lg"
        :default-center="center"
        :default-zoom="10"
        :min-zoom="minZoom"
        :mapbox-key="mapBoxKey"
        :markers="markers"
        :value="polygon"
        @input="onPolygonChange"
    >
        <destination-marker />
        <permanent-home-marker />
    </r-polygon>
</template>

<script>
import { icon } from 'utils/helpers';
import { mapGetters } from 'vuex';
import { Polygon as RPolygon } from '@relocity/ui';
import DestinationMarker from 'components/maps/markers/destination';
import PermanentHomeMarker from 'components/maps/markers/permanent-home';
import EmitsMapPositionUpdates from 'mixins/map/emits-map-position-updates.vue';

export default {
    mixins: [EmitsMapPositionUpdates],
    components: {
        DestinationMarker,
        PermanentHomeMarker,
        RPolygon,
    },
    props: {
        polygon: {
            type: Object,
            default: () => {},
        },
        results: {
            type: Array,
            default: () => [],
        },
        icon: {
            type: String,
            default: 'relocity-custom-marker',
        },
        minZoom: {
            type: Number,
            default: process.env.NODE_ENV === 'production' ? 11 : 0,
        },
    },
    data: () => ({
        mapBoxKey: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
        map: null,
    }),
    computed: {
        ...mapGetters('map', ['center', 'permanentHomeCoordinates']),
        markers() {
            const markerStyle = {
                width: '40px',
                height: '40px',
                backgroundSize: '100%',
                cursor: 'pointer',
            };

            const markers = this.results.map((record) => {
                const center = record.location ? record.location.coordinates : record.geometry.coordinates;
                return {
                    center,
                    icon: {
                        url: icon(this.icon),
                        style: markerStyle,
                    },
                    id: record.id,
                };
            });

            if (this.permanentHomeCoordinates) {
                const permanentHouseMarker = {
                    center: this.permanentHomeCoordinates,
                    icon: {
                        url: icon('relocity-permanent-house-marker'),
                        style: markerStyle,
                    },
                };

                markers.unshift(permanentHouseMarker);
            }

            const destinationMarker = {
                center: this.center,
                icon: {
                    url: icon('relocity-destination-marker'),
                    style: markerStyle,
                },
            };

            markers.unshift(destinationMarker);

            return markers;
        },
    },
    mounted() {
        this.map = this.$refs.polygon.map;
        this.setupMapPositionWatchers(this.map);
    },
    methods: {
        onPolygonChange(value) {
            this.$emit('change', value);
        },
        resize() {
            setTimeout(() => {
                this.$refs.polygon.map.resize();
            }, 25);
        },
    },
};
</script>
