<template>
    <div class="gallery-medias">
        <thumb
            v-for="media in medias"
            :key="media.id"
            :type="media.type"
            :status="getStatus(media)"
            :thumb="getThumbUrl(media)"
            @click="onMediaClick(media)"
        />
    </div>
</template>

<script>
import { get } from 'utils/lodash';
import Thumb from 'components/gallery/asset/thumb';
export default {
    components: {
        Thumb,
    },
    props: {
        gallery: {
            type: Object,
            required: true,
        },
        size: {
            type: Number,
            default: 170,
        },
    },
    computed: {
        medias() {
            return this.gallery.media;
        },
    },
    methods: {
        getStatus(media) {
            return get(media, 'asset.status');
        },
        getThumbUrl(media) {
            let status = get(media, 'asset.status');

            if (status != 'ready') {
                return '';
            }

            let size = this.size;

            if (media.type == 'video') {
                return media.asset.poster.animated + `?width=${size}`;
            } else {
                return media.asset.url + `?w=${size}&h=${size}&fit=crop`;
            }
        },
        onMediaClick(media) {
            this.$emit('click', media);
        },
    },
};
</script>
