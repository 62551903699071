<template>
    <div class="location">
        <r-autocomplete
            ref="autocomplete"
            :source="source"
            :class="classes"
            :display="({ description }) => description"
            property="data"
            value="description"
            :initial="value"
            @selected="onSelect"
            @clear="onClear"
        />
    </div>
</template>

<script>
import Place from 'models/Place';
import { mapGetters } from 'vuex';
export default {
    props: {
        classes: {
            type: [String, Array, Object],
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        radius: 80467, // 50 miles
    }),
    computed: {
        ...mapGetters('map', ['latitude', 'longitude']),
    },
    methods: {
        source(input) {
            return Place.autoCompleteUrl(input, this.latitude, this.longitude, this.radius);
        },
        onClear() {
            this.$emit('clear');
        },
        onSelect(destination) {
            Place.get(destination.selectedObject.place_id).then(({ data }) => {
                this.$emit('change', {
                    place: destination.selectedObject.description,
                    coordinate: {
                        latitude: data.geometry.coordinates[1],
                        longitude: data.geometry.coordinates[0],
                    },
                });
            });
        },
    },
};
</script>
