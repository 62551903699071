<template>
    <div class="flex justify-between space-y-2">
        <label :for="option.value" class="cursor-pointer">{{ option.label }}</label>
        <r-switch
            v-model="selection"
            :id="option.value"
            :name="option.value"
            :disabled="disabled"
            @click="onSelect"
            @keydown.enter.prevent="onSelect"
            @keydown.space.prevent="onSelect"
        />
    </div>
</template>

<script>
export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
        option: {
            type: Object,
            required: true,
        },
        value: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        selection: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('change', value, this.option);
            },
        },
    },
    methods: {
        onSelect() {
            this.$emit('change', value, this.option);
        },
    },
};
</script>
