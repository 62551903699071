<template>
    <div v-text="summary" />
</template>

<script>
import { get, join, startCase, isEmpty } from 'utils/lodash';
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    computed: {
        filters() {
            return get(this.layer, 'filters');
        },
        types() {
            return startCase(join(get(this.filters, 'type', [])));
        },
        level() {
            if (!isEmpty(get(this.filters, 'level'))) {
                return startCase(get(this.filters, 'level'));
            }

            return 'All';
        },
        distance() {
            return get(this.filters, 'distance', 0);
        },
        summary() {
            return `${this.distance} Mile Radius ${this.level} ${this.types} Schools`;
        },
    },
};
</script>
