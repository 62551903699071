<template>
    <div class="flex justify-between items-center space-y-2">
        <div class="flex flex-row space-x-2 justify-center items-center">
            <label :label="field.title" :for="field.slug" class="form__label text-primary">{{ field.title }}</label>
            <field-actions :field="field" :layer="layer" v-on="$listeners" />
        </div>
        <r-switch
            v-model="selection"
            :id="value"
            :name="value"
            :disabled="isLocked"
            @click="onSelect"
            @keydown.enter.prevent="onSelect"
            @keydown.space.prevent="onSelect"
        />
    </div>
</template>

<script>
import { includes } from '@/utils/lodash';
import FieldActions from '../actions.vue';
export default {
    components: {
        FieldActions,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
        layer: {
            type: Object,
            default: () => {},
        },
        value: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isLocked() {
            return includes(this.layer.filters?.locked_fields, this.field.id);
        },
        selection: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('change', value);
            },
        },
    },
    methods: {
        onSelect() {
            this.$emit('change', value);
        },
    },
};
</script>
