<template>
    <r-modal open fullscreen no-padding class="overflow-y-hidden" :dismissible="false" :title="title" @close="onClose">
        <template #header>
            <div class="header-container">
                <div class="flex flex-row justify-between w-full">
                    <div>
                        <h3 class="text-2xl leading-6 font-medium text-primary mb-2">{{ title }}</h3>
                        <h5 class="text-gray-600">
                            <r-icon icon-style="fad" name="layer-group" class="mr-1" />
                            {{ layerType }}
                        </h5>
                    </div>
                    <div class="space-x-1 header-actions">
                        <r-button variant="white" large square @click="onClose">Cancel </r-button>
                        <r-button square large @click="onSave">Save </r-button>
                    </div>
                </div>
                <div class="flex flex-row w-full mb-0 pt-3">
                    <div
                        id="filterTab"
                        class="header-tab px-2 cursor-pointer"
                        :class="activeTab === 'filter' ? activeTabClasses : ''"
                        @click="activeTab = 'filter'"
                    >
                        Filter
                    </div>
                    <div
                        id="exploreTab"
                        class="header-tab px-2 cursor-pointer"
                        :class="activeTab === 'explore' ? activeTabClasses : ''"
                        @click="activeTab = 'explore'"
                    >
                        Explore
                    </div>
                </div>
            </div>
        </template>

        <div
            class="grid grid-cols-1 md:grid-cols-2 gap-1 mx-auto"
            :class="activeTab === 'filter' ? '' : 'lg:grid-cols-3 xl:grid-cols-4'"
        >
            <div class="map-container" :class="activeTab === 'filter' ? '' : 'lg:col-span-2 xl:col-span-3'">
                <div class="relative w-full h-full">
                    <layer-map
                        ref="layerMap"
                        :icon="icon"
                        :results="results"
                        :polygon="polygon"
                        @change="onPolygonChange"
                    />
                    <display-count
                        class="absolute bottom-8 left-0 right-0 mx-auto"
                        :display-count="resultProperties.count"
                        :total-count="resultProperties.totalCount"
                    ></display-count>
                </div>
            </div>
            <div class="form-container bg-white">
                <div class="w-full mx-auto">
                    <layer-form
                        v-if="activeTab === 'filter'"
                        class="overflow-y-visible"
                        :layer="editLayer"
                        :category="category"
                        :filters="filters"
                        :providers="providers"
                        @change="onLayerChanges"
                        @filters-change="onFilterChange"
                        @lock-change="onLockChange"
                        @lock-map="onLockMapChange"
                        @hide-change="onHideChange"
                    />
                    <div v-else>
                        <div v-for="record in results" :key="record.id">
                            <mini-detail
                                :record="record"
                                :map-id="config.id"
                                :work-order-id="workOrder"
                                :layer-id="layerId"
                                :allow-favoriting="allowFavoriting"
                                class="relative shadow-md my-4 rounded-lg"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </r-modal>
</template>

<script>
import { size, map, join, merge, filter, cloneDeep, get, isEmpty, debounce, forEach } from '@/utils/lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import LayerMap from './polygon';
import LayerForm from './form';
import MiniDetail from 'components/maps/mini-detail';
import DisplayCount from 'components/maps/display-count';
import MapService from 'services/mapservice';

export default {
    components: {
        LayerMap,
        LayerForm,
        DisplayCount,
        MiniDetail,
    },
    props: {
        workOrder: {
            type: [String, Number],
        },
        layer: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        layerId: null,
        editLayer: {},
        activeTab: 'filter',
        activeTabClasses: 'border-b-2 border-solid avenir-bold border-black',
        filters: {},
        polygon: null,
        results: [],
        icon: 'relocity-custom-marker',
        resultProperties: { count: 0, totalCount: 0 },
        loading: false,
    }),
    computed: {
        ...mapGetters('map', ['center', 'config']),
        ...mapGetters('categories', ['findCategoryBySlug', 'findDataContentCategory']),
        ...mapState('records', ['dataContentRecords']),
        allowFavoriting() {
            return JSON.parse(process.env.VUE_APP_FEATURE_ENABLE_FAVORITING) && !isEmpty(this.workOrder);
        },
        cdnUrlPrefix() {
            return 'https://' + process.env.VUE_APP_RELOCITY_CDN;
        },
        likedIconUrl() {
            return this.cdnUrlPrefix + '/assets/map-icons/relocity-heart-icon.svg';
        },
        recommendedIconUrl() {
            return this.cdnUrlPrefix + '/assets/map-icons/relocity-recommended-heart-icon.svg';
        },
        map() {
            return this.$refs.layerMap.map;
        },
        isEditing() {
            return get(this.layer, 'id');
        },
        category() {
            return this.findCategoryBySlug(this.editLayer.category);
        },
        title() {
            return this.editLayer.name;
        },
        layerType() {
            return this.category.title;
        },
        hasPolygon() {
            return size(this.polygon) !== 0;
        },
        dataContentCategoriesProviders() {
            return map(this.findDataContentCategory(this.category.slug)?.providers, 'data_content_provider_id');
        },
        providers() {
            let providers = get(this.editLayer, 'providers');

            if (!isEmpty(providers)) {
                return providers;
            }

            return this.dataContentCategoriesProviders;
        },
        lockedMap() {
            return get(this.editLayer, 'filters.locked_map', false);
        },
        lockedFilters() {
            return get(this.editLayer, 'filters.locked_fields', []);
        },
        hiddenFilters() {
            return get(this.editLayer, 'filters.hidden_fields', []);
        },
        geometry() {
            if (!isEmpty(this.polygon)) {
                return this.polygon;
            }

            return {
                type: 'Point',
                coordinates: this.center,
            };
        },
        layerObject() {
            // convert the filtered fields from array to a CSV string.
            // if the data is already in CSV or if it's null, leave it as-is
            const fields = {};
            for (var i in this.filters) {
                const field = this.filters[i];
                fields[i] = Array.isArray(field) ? field.join(',') : field;
            }

            const layer = {
                name: this.editLayer.name,
                type: 'data_content',
                category: this.category.slug,
                visible: true,
                icon: this.icon,
                providers: this.providers,
                filters: {
                    geometry: this.geometry,
                    fields: fields,
                    locked_map: this.lockedMap,
                    locked_fields: this.lockedFilters,
                    hidden_fields: this.hiddenFilters,
                },
            };

            if (this.editLayer.id) {
                layer.id = this.layerId;
            }

            return layer;
        },
    },
    watch: {
        filters: {
            deep: true,
            handler: 'onQueryRecords',
        },
        polygon: {
            handler: 'onQueryRecords',
        },
        layer: {
            immediate: true,
            handler: 'onLayerChange',
        },
        ['editLayer.icon']: {
            immediate: true,
            handler: 'onIconChange',
        },
        ['editLayer.providers']: {
            immediate: true,
            handler: 'onQueryRecords',
        },
        activeTab: {
            immediate: true,
            handler: 'onActiveTabChange',
        },
        dataContentRecords: {
            deep: true,
            handler: 'onRecordsChange',
        },
    },
    created() {
        this.handleEditLayer();
        this.$events.$on('map:position:update', this.onMapPositionUpdate);
        this.results = [];
        this.onQueryRecords();
    },
    beforeDestroy() {
        this.$events.$off('map:position:update', this.onMapPositionUpdate);
    },
    methods: {
        ...mapActions('records', ['search']),
        ...mapActions('map', ['addLayer', 'updateLayer', 'saveLayer']),
        ...mapActions('layers', ['setEditing', 'clearEditing']),
        onMapPositionUpdate(data) {
            // only react to updates to our map
            if (this.map == data.map) {
                this.onQueryRecords();
            }
        },
        handleEditLayer() {
            this.layerId = this.editLayer.id;

            // it may return a polygon or a point. We want to ignore the point
            this.polygon =
                size(this.editLayer.filters?.geometry?.coordinates[0][0]) !== 0
                    ? cloneDeep(this.editLayer.filters?.geometry)
                    : null;

            this.icon = 'relocity-custom-marker';
            if (this.layer?.icon) {
                this.icon = this.layer.icon;
            } else if (this.category?.icon) {
                this.icon = this.category.icon;
            }
            this.filters = cloneDeep(this.editLayer.filters?.fields ?? {});

            this.setEditing(cloneDeep(this.editLayer));
        },
        onLayerChange(layer) {
            this.editLayer = cloneDeep(layer);
        },
        // two reactive watchers fire at the same time,
        // so to prevent double api calls, we debounce on 1ms
        onQueryRecords: debounce(async function () {
            const response = await this.search({
                category: this.category.slug,
                query: this.searchQueryParams(),
            });

            this.results = this.layer.id ? this.matchResultsWithLayerData(response.features) : response.features;
            this.resultProperties = response.properties;
            this.onResultsChange();
        }, 1),
        onResultsChange() {
            setTimeout(() => {
                this.results.forEach((result) => {
                    const el = document.getElementById(result.id);
                    el.classList.add('z-50');

                    const container = document.createElement('div');
                    container.setAttribute('id', result.id + '-container');
                    container.classList.add('w-full', 'h-full', 'z-50', 'marker-container');

                    if (el.firstChild) {
                        return;
                    }

                    if (result.properties?.is_liked) {
                        const liked = document.createElement('img');
                        liked.setAttribute('src', this.likedIconUrl);
                        liked.classList.add('is-liked');

                        container.appendChild(liked);
                    }

                    if (result.properties?.is_recommended) {
                        const recommended = document.createElement('img');
                        recommended.setAttribute('src', this.recommendedIconUrl);
                        recommended.classList.add('is-recommended');

                        container.appendChild(recommended);
                    }

                    el.appendChild(container);
                });
            }, 50);
        },
        onRecordsChange() {
            this.onQueryRecords();
        },
        onTabChange(tab) {
            this.activeTab = tab.name;
        },
        onLayerChanges(key, value) {
            if (value === '' || value === null || size(value) === 0) {
                this.$set(this.editLayer, key, Array.isArray(value) ? [] : '');
            } else {
                this.$set(this.editLayer, key, value);
            }
        },
        onLockChange(field, value) {
            let items = this.lockedFilters;
            if (value) {
                items.push(field.id);
            } else {
                items = filter(items, (i) => i !== field.id);
            }

            this.$set(this.editLayer.filters, 'locked_fields', items);
        },
        onHideChange(field, value) {
            let items = this.hiddenFilters;
            if (value) {
                items.push(field.id);
            } else {
                items = filter(items, (i) => i !== field.id);
            }

            this.$set(this.editLayer.filters, 'hidden_fields', items);
        },
        onLockMapChange(value) {
            this.$set(this.editLayer.filters, 'locked_map', value);
        },
        onFilterChange({ field, value }) {
            if (typeof value !== 'boolean' && (value === '' || value === null || size(value) === 0)) {
                // this.$delete(this.filters, field.id);
                this.$set(this.filters, field.id, '');
            } else {
                this.$set(this.filters, field.id, value);
            }
        },
        onPolygonChange(polygon) {
            this.polygon = polygon;
        },
        onIconChange(icon) {
            this.icon = icon;
        },
        async onSave() {
            this.loading = true;

            if (this.layerObject.id == null) {
                // create new map
                const res = await this.addLayer(this.layerObject);
                this.layerId = res.id;
            } else {
                // save existing map. We first update before saving because
                // when changing from a POINT to a POLYGON, the `coordinates` property
                // incorrectly holds on to the 2nd element of the POINT array during merge,
                // causing an error. So we update first, then merge+save
                this.updateLayer(this.layerObject);
                this.saveLayer(this.layerObject);
            }

            this.loading = false;

            this.onChanged();
        },
        onClose() {
            this.clearEditing();
            this.$emit('close');
        },
        onChanged() {
            this.clearEditing();
            this.$emit('changed', this.layerObject);
        },

        /* search query parameters */
        searchQueryParams() {
            const resultsQuery = { results: 100 };

            if (this.hasPolygon) {
                return merge(
                    { geojson: 1 },
                    this.polygonQuery(),
                    this.filtersQuery(),
                    this.boundingBoxQuery(),
                    resultsQuery
                );
            }

            return merge({ geojson: 1 }, this.pointQuery(), this.filtersQuery(), this.boundingBoxQuery(), resultsQuery);
        },
        boundingBoxQuery() {
            const boundingBox = MapService.getBoundingBox(this.map);
            const filters = {};
            map(boundingBox, (coordinates, index) => {
                filters[`filter[boundingBox][${index}]`] = `${coordinates.lng},${coordinates.lat}`;
            });

            return filters;
        },
        polygonQuery() {
            if (!this.hasPolygon) {
                return null;
            }

            const { coordinates } = this.polygon;
            let filters = {};
            map(coordinates[0], (polygon, index) => {
                filters[`filter[polygon][${index}]`] = join(polygon, ',');
            });

            return filters;
        },
        pointQuery() {
            return {
                'filter[point]': join(this.center, ','),
            };
        },
        filtersQuery() {
            let filters = {};
            map(this.filters, (value, id) => {
                filters[`filter[fields][${id}]`] = value;
            });

            if (!isEmpty(this.providers)) {
                filters['filter[providers]'] = this.providers;
            }

            return filters;
        },
        onActiveTabChange() {
            if (!this.$refs.layerMap) {
                return;
            }
            this.$refs.layerMap.resize();
        },
        matchResultsWithLayerData(results) {
            return map(results, (result) => {
                result.properties.record_id = result.id;
                forEach(this.dataContentRecords[this.layerId].features, (existingRecord) => {
                    if (result.id === existingRecord.id) {
                        result.properties.is_liked = existingRecord.properties.is_liked;
                        result.properties.is_recommended = existingRecord.properties.is_recommended;

                        if (existingRecord.properties.platform_id) {
                            result.properties.platform_id = existingRecord.properties.platform_id;
                        } else {
                            delete result.properties.platform_id;
                        }
                    }
                });
                return result;
            });
        },
    },
};
</script>

<style lang="scss">
.form-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(96vh - 75px);
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    & > div {
        width: 75%;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

.map-container {
    height: calc(96vh - 75px);
}

.header-container {
    padding-left: 3rem;
    padding-right: 3rem;
    border-bottom-width: 2px;
    border-style: solid;
    position: sticky;
    top: 0;
    z-index: 10;
    margin-top: 0.5rem;

    .header-actions {
        margin-top: 0;
    }
}

.header-tab {
    &:hover {
        font-family: 'Avenir-Medium', 'Avenir-Heavy', 'sans-serif';
    }
}

.avenir-bold {
    font-family: 'Avenir-Medium', 'Avenir-Heavy', 'sans-serif';
}

.is-liked {
    position: absolute;
    top: 1px;
    right: -4px;
    z-index: 20;
}

.is-recommended {
    position: absolute;
    top: -4px;
    right: 1px;
    z-index: 10;
}

@media (max-width: 691px) {
    .form-container {
        height: 100%;

        & > div {
            width: 100%;
            padding-bottom: 2rem;
            padding-top: 2rem;
        }
    }

    .map-container {
        display: none;
    }

    .header-container {
        flex-direction: column;
        margin-top: 0.5rem;
        margin-bottom: 0rem;

        .header-actions {
            margin-top: 1rem;
        }
    }
}
</style>
