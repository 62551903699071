<template>
    <div>
        <p class="expanded-subheader">Filter by distance:</p>
        <r-select id="radius" name="radius" v-model="radius" :options="options" @change="(value) => (radius = value)" />
    </div>
</template>

<script>
import { map, get } from 'utils/lodash';
import { mapActions } from 'vuex';
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        radius: ['1', '2', '3', '4', '5'],
    }),
    computed: {
        radius: {
            get() {
                return get(this.layer, 'filters.radius');
            },
            set(value) {
                this.updateLayer({
                    ...this.layer,
                    filters: { ...this.layer.filters, radius: value },
                });
            },
        },
        options() {
            return map(this.radius, (radius) => {
                return { key: radius, title: `${radius} Miles Distance` };
            });
        },
    },
    methods: {
        ...mapActions('map', ['updateLayer']),
    },
};
</script>
