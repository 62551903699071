<template>
    <r-input-group label="Link Type" class="square-select mb-6">
        <r-select
            id="url-type-input"
            name="url-type-input"
            empty
            v-model="index"
            :options="options"
            @change="onChange"
        />
    </r-input-group>
</template>

<script>
import { map, findIndex } from 'utils/lodash';
export default {
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        types: [
            { label: '360 Video', value: '360 Video' },
            { label: 'Video', value: 'Video' },
            { label: 'Website', value: 'Website' },
            { label: 'YouTube', value: 'Website' },
        ],
        index: null,
    }),
    mounted() {
        this.index = findIndex(this.types, (type) => {
            return type.value == this.value;
        });
    },
    computed: {
        options() {
            return map(this.types, (type, index) => {
                return { key: index, title: type.label };
            });
        },
    },
    methods: {
        onChange(index) {
            this.$emit('change', this.types[index].value);
        },
    },
};
</script>
