<template>
    <div v-text="summary" />
</template>

<script>
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    computed: {
        summary() {
            return '';
        },
    },
};
</script>
