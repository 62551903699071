<template>
    <div class="grid grid-flow-col grid-cols-2 gap-x-10">
        <field-select
            :show-actions="false"
            :field="minField"
            :value="minValue"
            :layer="layer"
            @change="onMinChange"
            @lock="onToggleLock"
            @hide="onToggleVisibility"
        >
            <template #label>
                <div class="flex flex-row space-x-2 justify-center items-center">
                    <label :label="field.title" :for="field.slug" class="form__label text-primary">{{
                        field.title
                    }}</label>
                    <field-actions :field="field" :layer="layer" v-on="$listeners">
                        <template #prefix>
                            <r-icon
                                v-tooltip.top="'Locking or hiding range filters will be applied to both filters.'"
                                name="question-circle"
                                icon-style="fal"
                                variant="secondary"
                            />
                        </template>
                    </field-actions>
                </div>
            </template>
        </field-select>
        <field-select :show-actions="false" :field="maxField" :value="maxValue" :layer="layer" @change="onMaxChange" />
    </div>
</template>

<script>
import { isEmpty } from '@/utils/lodash';
import FieldSelect from './select.vue';
import FieldActions from '../actions.vue';
import { tooltip } from '@relocity/ui';
export default {
    components: {
        FieldSelect,
        FieldActions,
    },
    directives: {
        tooltip,
    },
    props: {
        layer: {
            type: Object,
            default: () => {},
        },
        field: {
            type: Object,
            required: true,
        },
        value: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        minValue: '',
        maxValue: '',
    }),
    computed: {
        minField() {
            return {
                title: `Min ${this.field.title}`,
                slug: this.field.slug,
                id: this.field.id,
                options: this.field.filters.min,
                filters: this.field.filters,
            };
        },
        maxField() {
            return {
                title: `Max ${this.field.title}`,
                slug: this.field.slug,
                id: this.field.id,
                options: this.field.filters.max,
                filters: this.field.filters,
            };
        },
    },
    watch: {
        value: {
            immediate: true,
            handler: 'onValueChange',
        },
    },
    methods: {
        onValueChange() {
            if (isEmpty(this.value)) {
                return;
            }

            const [min, max] = this.value.split(',');
            this.minValue = min;
            this.maxValue = max;
        },
        onMinChange(val) {
            this.minValue = val;
            this.onChange();
        },
        onMaxChange(val) {
            this.maxValue = val;
            this.onChange();
        },
        onChange() {
            let range = [this.minValue, this.maxValue];
            if (isEmpty(this.minValue) && isEmpty(this.maxValue)) {
                this.$emit('change', '');
            } else {
                this.$emit('change', range.join(','));
            }
        },
        onToggleLock(field, value) {
            this.$emit('lock', field, value);
        },
        onToggleVisibility(field, value) {
            this.$emit('hide', field, value);
        },
    },
};
</script>
