<template>
    <r-input-group>
        <template #label>
            <slot name="label">
                <div class="flex flex-row space-x-2 justify-center items-center">
                    <label :label="field.title" :for="field.slug" class="form__label text-primary">{{
                        field.title
                    }}</label>
                    <field-actions v-if="showActions" :field="field" :layer="layer" v-on="$listeners" />
                </div>
            </slot>
        </template>
        <r-ui-select
            :id="field.slug"
            :name="field.slug"
            v-model="myValue"
            track-by="value"
            label="label"
            :multiple="field.is_multiselect"
            :options="field.options"
            :disabled="isLocked"
            @input="onChange"
        />
    </r-input-group>
</template>

<script>
import { map, has, includes } from '@/utils/lodash';
import FieldActions from '../actions.vue';
export default {
    components: {
        FieldActions,
    },
    props: {
        showActions: {
            type: Boolean,
            default: true,
        },
        layer: {
            type: Object,
            default: () => {},
        },
        field: {
            type: Object,
            required: true,
        },
        value: {
            type: [Object, String, Number, Array],
            default: null,
        },
    },
    data: () => ({
        myValue: [],
    }),
    computed: {
        isLocked() {
            return includes(this.layer.filters?.locked_fields, this.field.id);
        },
    },
    created() {
        if (this.value == null) {
            return;
        }

        // load initial value for single/multi select
        if (!this.field.is_multiselect) {
            this.myValue = this.field.options.find((option) => option.value == this.value);
        } else {
            this.myValue = this.field.options.filter((option) => this.value.includes(option.value));
        }
    },
    methods: {
        onChange(values) {
            if (has(values, 'value')) {
                this.$emit('change', values.value);
            } else {
                this.$emit('change', map(values, 'value'));
            }
        },
    },
};
</script>
