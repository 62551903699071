<template>
    <portal to="navigation-menu">
        <a class="sub-menu-option cursor-pointer" @click="onClick">Import / Export</a>
    </portal>
</template>

<script>
export default {
    methods: {
        onClick() {
            this.$emit('click');
        },
    },
};
</script>
