<template>
    <div class="flex flex-col space-y-4">
        <filter-field
            v-for="field in fields"
            :key="field.id"
            :filters="filters"
            :layer="layer"
            :field="field"
            @change="onChange"
            @lock="onToggleLock"
            @hide="onToggleHide"
        />
    </div>
</template>

<script>
import FilterField from '@/components/fields/filter-field.vue';
export default {
    components: {
        FilterField,
    },
    props: {
        layer: {
            type: Object,
            default: () => {},
        },
        section: {
            type: Object,
            required: true,
        },
        filters: {
            type: [Object, Array],
            required: true,
        },
    },
    computed: {
        fields() {
            return this.section.fields.filter((field) => field.is_filterable);
        },
    },
    methods: {
        onChange(args) {
            this.$emit('change', args);
        },
        onToggleLock(field, value) {
            this.$emit('lock', field, value);
        },
        onToggleHide(field, value) {
            this.$emit('hide', field, value);
        },
    },
};
</script>
