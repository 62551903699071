<template>
    <div class="image-modal-wrapper">
        <r-icon class="close-btn" name="times-circle" icon-style="fad" color="#aaa" size="lg" @click.stop="onClose" />
        <img :src="src" class="photo-preview" />
    </div>
</template>

<script>
export default {
    props: {
        media: {
            type: Object,
            required: true,
        },
    },
    computed: {
        src() {
            return this.media.asset.url + '?w=750&h=730&fit=crop';
        },
    },
    methods: {
        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.image-modal-wrapper {
    height: 100%;
    position: relative;
    width: auto;
    display: inline-block;

    .close-btn {
        z-index: 2;
        position: absolute;
        margin-top: -23px;
    }

    img.photo-preview {
        max-width: 100%;
        max-height: 100%;
        border-radius: 5px;
    }
}
</style>
