<template>
    <div v-text="summary" />
</template>

<script>
import { get, map, startCase } from 'utils/lodash';
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    computed: {
        filters() {
            return get(this.layer, 'filters');
        },
        profile() {
            return startCase(get(this.filters, 'profile', ''));
        },
        minutes() {
            return map(get(this.filters, 'contours_minutes'), (minute) => `${minute} Min`);
        },
        summary() {
            return `${this.profile} ${this.minutes}`;
        },
    },
};
</script>
