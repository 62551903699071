<template>
    <div class="assets-wrapper">
        <div v-if="headers" class="header-wrapper">
            <div class="header-label reorder">Order</div>
            <div class="header-label thumb">Thumb</div>
            <div class="header-label title-caption">Title &amp; Caption</div>
            <div class="header-label">Format</div>
            <div class="header-label option">Options</div>
        </div>
        <r-draggable v-model="assets" @change="onSort">
            <asset
                v-for="asset in assets"
                :key="asset.id"
                :media="asset"
                :gallery="gallery"
                sortable
                @click="onMediaClick"
                @deleted="onMediaDeleted"
                @updated="onMediaUpdated"
            />
        </r-draggable>
    </div>
</template>

<script>
import { map } from 'utils/lodash';
import { replace } from 'utils/helpers';
import Asset from './asset/asset';
export default {
    components: {
        Asset,
    },
    props: {
        headers: {
            type: Boolean,
            default: false,
        },
        assets: {
            type: [Array, Object],
            default: () => [],
        },
        gallery: {
            type: [Object, Array],
            default: () => {},
        },
    },
    methods: {
        onMediaUpdated(asset) {
            replace(this.assets, { id: asset.id }, asset);

            this.$emit('changes', 'updated', asset);
        },
        onMediaDeleted(asset) {
            this.$emit('changes', 'deleted', asset);
        },
        onMediaClick(media) {
            this.$emit('click', media);
        },
        onSort(list) {
            let i = 0;
            let assets = [];
            map(list, (item) => {
                assets.push({ ...item, order: ++i });
            });

            this.$emit('sort', assets);
        },
    },
};
</script>

<style lang="scss" scoped>
.assets-wrapper {
    max-height: 400px;
    overflow: scroll;
}
</style>
