<template>
    <r-modal open class="grey w-full" modal-width="w-full sm:w-3/4 md:w-2/3 lg:w-1/2" @close="onClose">
        <div class="modal-title">Gallery</div>

        <uploader
            @change="onFileChange"
            @success="onSuccess"
            @errror="onError"
            @sending="onSending"
            @queue-completed="onQueueCompleted"
        />

        <assets
            v-if="assets"
            :assets="assets"
            :gallery="gallery"
            headers
            @changes="onChanges"
            @sort="onSort"
            @click="onMediaClick"
        />
    </r-modal>
</template>

<script>
import { get, sortBy, map } from 'utils/lodash';
import { replace } from 'utils/helpers';
import Assets from './assets';
import Uploader from './uploader';
import { mapActions, mapGetters } from 'vuex';
export default {
    components: {
        Assets,
        Uploader,
    },
    props: {
        index: {
            type: [String, Number],
            default: null,
        },
        layer: {
            type: Object,
            required: true,
        },
        gallery: {
            type: [Object, Array],
            default: () => ({
                media: [],
            }),
        },
    },
    computed: {
        ...mapGetters('map', ['id']),
        hasGallery() {
            return get(this.annotation, 'gallery.id') !== undefined;
        },
        assets() {
            return sortBy(get(this.gallery, 'media'), ['order']);
        },
        annotation() {
            return get(this.layer.annotations, this.index);
        },
        galleryTitle() {
            return `${this.annotation?.name} Gallery`;
        },
    },
    async created() {
        await this.ensureGallery();
    },
    methods: {
        ...mapActions('map', ['updateAnnotation']),
        ...mapActions('gallery', {
            createGallery: 'create',
            sortAssets: 'sort',
        }),
        ...mapActions('assets', { createMedia: 'create' }),
        onFileChange(file) {
            this.$emit('file-change', file);
        },
        onQueueCompleted() {
            this.$emit('completed');
        },
        onSending(file, xhr) {
            xhr.filename = file.name;
            xhr.ontimeout = this.onTimeout;
        },
        onTimeout(event) {
            this.$error(`Your upload has timed out: ${event.target.filename}`, {
                sticky: true,
            });
        },
        onSuccess(file) {
            this.handleAsset(file);
        },
        onError(file, message) {
            this.$error(`${message}: ${file.name}`, { sticky: true });
        },
        onClose() {
            this.$emit('close');
        },
        onMediaClick(media) {
            this.$emit('click', media);
        },
        async handleAsset(file) {
            let gallery = await this.ensureGallery();

            try {
                await this.createMedia({
                    map: this.id,
                    gallery: gallery.id,
                    file: file,
                });
            } catch (e) {
                this.$error(`Error Upload Gallery Asset: ${e}`);
            }

            // Reload Gallery
            this.$emit('uploaded', file);
        },
        async ensureGallery() {
            if (!this.hasGallery) {
                let gallery = await this.createGallery({
                    map: this.id,
                    title: this.galleryTitle,
                });

                await this.updateAnnotation({
                    layer: this.layer,
                    index: this.index,
                    annotation: {
                        ...this.annotation,
                        gallery: { id: gallery.id },
                    },
                });

                return gallery;
            }

            return this.annotation.gallery;
        },
        onChanges(type, media) {
            if (type == 'updated') {
                replace(this.gallery.media, { id: media.id }, media);
            }

            this.$emit('changed');
        },
        async onSort(assets) {
            this.$emit('sort', assets);

            await this.sortAssets({
                gallery: this.gallery.id,
                ids: map(assets, 'id'),
            });

            this.onChanges();
        },
    },
};
</script>
