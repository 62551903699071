<template>
    <div>
        <navigation-menu @click="onMenuClick" />
        <modal v-if="showModal" @close="showModal = false">
            <r-tabs full :active="activeTab" @change="onTabChange">
                <r-tab name="import" title="Import">
                    <import-tab @imported="onImported" />
                </r-tab>
                <r-tab name="export" title="Export">
                    <export-tab />
                </r-tab>
            </r-tabs>
        </modal>
    </div>
</template>

<script>
import Modal from './modal.vue';
import ExportTab from './export-tab.vue';
import ImportTab from './import-tab.vue';
import NavigationMenu from './navigation-menu.vue';
export default {
    components: {
        Modal,
        ExportTab,
        ImportTab,
        NavigationMenu,
    },
    data: () => ({
        activeTab: 'import',
        showModal: false,
    }),
    methods: {
        onMenuClick() {
            this.showModal = true;
        },
        onTabChange(tab) {
            this.activeTab = tab.name;
        },
        onImported() {
            this.$emit('change');
            this.showModal = false;
        },
    },
};
</script>
