<template>
    <r-input-group>
        <template #label>
            <div class="flex flex-row space-x-2 justify-center items-center">
                <label :label="field.title" :for="field.slug" class="form__label text-primary">{{ field.title }}</label>
                <field-actions :field="field" :layer="layer" v-on="$listeners" />
            </div>
        </template>
        <r-button-group :id="field.slug" :name="field.slug" class="shadow rounded-full">
            <r-button
                v-for="option in field.options"
                :key="option.label"
                :aria-label="option.label"
                role="button"
                :disabled="isLocked"
                :aria-pressed="isSelected(option)"
                :variant="buttonVariant(option)"
                @click="onSelect(option)"
                @keydown.enter.prevent="onSelect(option)"
                @keydown.space.prevent="onSelect(option)"
            >
                {{ option.label }}
            </r-button>
        </r-button-group>
    </r-input-group>
</template>

<script>
import { get, includes } from '@/utils/lodash';
import FieldActions from '../actions.vue';
import { ButtonGroup as RButtonGroup } from '@relocity/ui';
export default {
    components: {
        FieldActions,
        RButtonGroup,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
        layer: {
            type: Object,
            default: () => {},
        },
        value: {
            type: [Array, String],
        },
    },
    computed: {
        isMultiple() {
            return get(this.field, 'is_multiselect');
        },
        isLocked() {
            return includes(this.layer.filters?.locked_fields, this.field.id);
        },
    },
    methods: {
        onSelect(option) {
            let selected = this.isSelected(option);
            let newValue;
            if (this.isMultiple) {
                if (selected) {
                    newValue = this.value.filter((val) => val !== option.value);
                } else {
                    newValue = this.value ? [...this.value, option.value] : [option.value];
                }
            } else {
                newValue = !selected ? option.value : null;
            }

            this.$emit('change', newValue);
        },
        isSelected({ value }) {
            let selected = false;
            if (this.isMultiple) {
                if (this.value) {
                    for (let val of this.value) {
                        if (value === val) {
                            selected = true;
                            break;
                        }
                    }
                }
            } else {
                selected = this.value === value;
            }
            return selected;
        },
        buttonVariant(option) {
            return this.isSelected(option) ? 'primary' : 'white';
        },
    },
};
</script>
