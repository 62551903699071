<template>
    <form @submit.prevent>
        <r-input-group label="Map Title" class="mb-6">
            <r-input id="map-title" name="map-title" v-model="title" />
        </r-input-group>
        <r-input-group label="Destination Title" class="mb-6">
            <r-input id="destination-title" name="destination-title" v-model="destination_title" />
        </r-input-group>
        <r-input-group label="Destination Address" class="mb-6">
            <destination
                v-if="destination"
                :initial="destination"
                class="general-destination"
                @change="onDestinationSelected"
            />
        </r-input-group>

        <r-input-group label="Map Provider" class="mb-6">
            <r-ui-select
                id="map-provider"
                name="map-provider"
                v-model="provider"
                :options="mapProviders"
                @input="onProviderChange"
            />
        </r-input-group>
    </form>
</template>

<script>
import { debounce, get } from 'utils/lodash';
import { mapGetters, mapActions, mapState } from 'vuex';
import Destination from 'components/layers/form/destination';
export default {
    components: {
        Destination,
    },
    computed: {
        ...mapState('mapProviders', ['mapProviders']),
        ...mapGetters('map', ['config', 'destination']),
        title: {
            get() {
                return get(this.config, 'title');
            },
            set: debounce(function (value) {
                this.updateMap({ title: value });
            }, 1000),
        },
        destination_title: {
            get() {
                return get(this.config, 'destination_title');
            },
            set: debounce(function (value) {
                this.updateMap({ destination_title: value });
            }, 1000),
        },
        provider: {
            get() {
                return get(this.config, 'provider');
            },
            set(value) {
                this.updateMap({ provider: value });
            },
        },
    },
    methods: {
        ...mapActions('map', ['updateMap', 'setChangingProvider']),
        onDestinationSelected(destination) {
            this.updateMap({
                destination: destination.display,
                center: {
                    longitude: destination.value[0],
                    latitude: destination.value[1],
                },
            });
        },
        onProviderChange(value) {
            this.setChangingProvider(true);

            this.updateMap({ provider: value });
            this.$events.$emit('map:updated');
        },
    },
};
</script>
<style lang="scss">
.general-destination {
    .autocomplete__box {
        border: 1px solid #e2e8f0 !important;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
        border-radius: 0.25rem !important;
        color: #4a5568;
        width: 100%;
        line-height: 1.25 !important;
        padding: 0.5rem 0.75rem !important;
    }
}
</style>
