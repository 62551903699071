<template>
    <r-modal class="map-share-modal" open large title="Import / Export Map JSON" @close="onClose">
        <slot />
    </r-modal>
</template>

<script>
export default {
    methods: {
        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss">
.map-share-modal {
    .modal__dialog {
        max-width: 80rem;
    }
}
</style>
