<template>
    <annotation :title="title" @back="onBack" @copy="onCopy">
        <form @submit.prevent>
            <r-input-group label="Name" class="mb-6">
                <r-input
                    id="name-input"
                    name="name-input"
                    placeholder="Enter name of Annotation"
                    v-model="name"
                    :class="{ invalid: $v.annotation.name.$error }"
                />
            </r-input-group>
            <r-input-group label="Place" class="mb-6">
                <annotation-location
                    :value="annotation.place"
                    :classes="{ invalid: $v.annotation.place.$error }"
                    @change="onLocationChange"
                    @clear="onLocationClear"
                />
            </r-input-group>
            <r-input-group label="Location" class="mb-6">
                <r-input
                    id="location-input"
                    name="location-input"
                    placeholder="Latitude, Longitude"
                    v-model="location"
                    :readonly="true"
                />
            </r-input-group>
            <r-input-group label="Description" class="mb-6">
                <r-textarea
                    id="description-input"
                    name="description-input"
                    placeholder="Enter a description"
                    v-model="description"
                />
            </r-input-group>

            <annotation-url-type :value="annotation.url_type" @change="onUrlTypeChange" />

            <div v-if="hasUrlType">
                <r-input-group label="Link" class="mb-6">
                    <r-input
                        id="url-input"
                        name="url-input"
                        v-model="annotation.url"
                        :class="{ invalid: $v.annotation.name.$error }"
                    />
                </r-input-group>
            </div>

            <gallery :index="index" :layer="layer" />

            <r-button large @click.stop="onSubmit">Save</r-button>
        </form>
    </annotation>
</template>

<script>
import { get, isEmpty, debounce } from 'utils/lodash';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import Annotation from 'components/layers/form/annotation';
import AnnotationLocation from './location';
import AnnotationUrlType from 'components/layers/form/urltype';
import { mapActions, mapGetters } from 'vuex';
import Gallery from 'components/layers/types/common/components/annotations/gallery/gallery';

export default {
    mixins: [validationMixin],
    components: {
        Gallery,
        Annotation,
        AnnotationUrlType,
        AnnotationLocation,
    },
    props: {
        creating: {
            type: Boolean,
            default: false,
        },
        index: {
            type: [String, Number],
            default: null,
        },
        layer: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        location: '',
    }),
    validations: {
        annotation: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100),
            },
            place: {
                required,
                minLength: minLength(2),
            },
        },
    },
    computed: {
        ...mapGetters('map', ['id']),
        title() {
            return this.creating ? 'Add Annotation' : 'Editing Annotation';
        },
        hasUrlType() {
            return !isEmpty(this.annotation.url_type);
        },
        annotationHasGallery() {
            return get(this.annotation, 'gallery.id') !== undefined;
        },
        annotation() {
            return get(this.layer.annotations, this.index);
        },
        description: {
            get() {
                return this.annotation.description;
            },
            set(value) {
                this.onUpdateAnnotation({ description: value });
            },
        },
        name: {
            get() {
                return this.annotation.name;
            },
            set(value) {
                this.onUpdateAnnotation({ name: value });
            },
        },
        url: {
            get() {
                return this.annotation.url;
            },
            set(value) {
                this.onUpdateAnnotation({ url: value });
            },
        },
    },
    created() {
        this.setEditingAnnotation(this.annotation);
    },
    beforeDestroy() {
        this.endEditingAnnotation();
    },
    watch: {
        annotation: {
            immediate: true,
            handler: function () {
                if (get(this.annotation, 'coordinate')) {
                    if (this.annotation.coordinate.latitude && this.annotation.coordinate.longitude) {
                        this.location = `${this.annotation.coordinate.latitude}, ${this.annotation.coordinate.longitude}`;
                    } else {
                        this.location = '';
                    }
                }
            },
        },
    },
    methods: {
        ...mapActions('gallery', { createGallery: 'create' }),
        ...mapActions('map', ['addAnnotation', 'updateAnnotation', 'saveLayer']),
        ...mapActions('annotation', {
            setEditingAnnotation: 'edit',
            endEditingAnnotation: 'cancel',
        }),
        onBack() {
            this.$emit('back');
        },
        onCopy() {
            this.$emit('copy');
        },
        async onSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                await this.validateAndUpdateAnnotation({
                    annotation: this.annotation,
                });

                // Save layer
                await this.saveLayer(this.layer);

                this.onBack();
            }
        },
        async onLocationChange(destination) {
            let name = this.annotation.name;

            this.location = `${destination.coordinate.latitude}, ${destination.coordinate.longitude}`;

            if (!this.annotation.name) {
                name = destination.place;
            }

            await this.validateAndUpdateAnnotation({
                name: name,
                place: destination.place,
                coordinate: destination.coordinate,
                location: this.location,
            });

            // update the editing annotation so the map flies to the new location
            this.setEditingAnnotation(this.annotation);
        },
        async onLocationClear() {
            await this.validateAndUpdateAnnotation({
                place: null,
                coordinate: {},
                location: null,
            });
        },
        onUrlTypeChange(type) {
            this.$forceUpdate();
            this.validateAndUpdateAnnotation({ url_type: type });
        },
        onUpdateAnnotation: debounce(function (changes) {
            this.validateAndUpdateAnnotation(changes);
        }, 500),
        async validateAndUpdateAnnotation(changes) {
            // Check if we have a duplicate annotation name in this layer
            const duplicate = this.layer.annotations.find(
                (annotation, index) => changes.name && annotation.name === changes.name && index !== this.index
            );

            if (!isEmpty(duplicate)) {
                this.$error('Annotation name already exists in this layer. please choose another one.');
                return;
            }

            await this.updateAnnotation({
                layer: this.layer,
                index: this.index,
                annotation: { ...this.annotation, ...changes },
            });
        },
    },
};
</script>
