<template>
    <r-input-group :label="{ label: field.title, for: field.slug }">
        {{ field.statement }}
    </r-input-group>
</template>

<script>
export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
};
</script>
