<template>
    <div class="flex flex-row space-x-2 justify-center items-center" :class="{ hidden: !isFeatureEnabled }">
        <slot name="prefix" />
        <r-icon :name="lockedIcon" icon-style="fas" variant="secondary" class="cursor-pointer" @click="onToggleLock" />
        <r-icon
            :name="visibleIcon"
            icon-style="fas"
            variant="secondary"
            class="cursor-pointer"
            @click="onToggleVisibility"
        />
    </div>
</template>

<script>
import { get, includes } from '@/utils/lodash';
export default {
    props: {
        layer: {
            type: Object,
            default: () => {},
        },
        field: {
            type: Object,
            required: true,
        },
    },
    computed: {
        locked() {
            return includes(get(this.layer, 'filters.locked_fields', []), this.field.id);
        },
        hidden() {
            return includes(get(this.layer, 'filters.hidden_fields', []), this.field.id);
        },
        lockedIcon() {
            return this.locked ? 'lock' : 'lock-open';
        },
        visibleIcon() {
            return this.hidden ? 'eye-slash' : 'eye';
        },
        isFeatureEnabled() {
            return process.env.VUE_APP_FEATURE_ENABLE_LOCK_HIDE === 'true';
        },
    },
    methods: {
        onToggleLock() {
            this.$emit('lock', this.field, !this.locked);
        },
        onToggleVisibility() {
            this.$emit('hide', this.field, !this.hidden);
        },
    },
};
</script>
