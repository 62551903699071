<template>
    <wrapper class="flex-grow-1">
        <slot />
        <div id="disable-map" />
        <r-loading-bounce v-if="loading" class="h-100 w-full" />

        <div v-else class="flex-grow-1">
            <maps-header :user="user" :work-order="workOrder" />
            <div class="outer layers-page edit-map-container">
                <main class="inner-container">
                    <div class="layers">
                        <div class="panel-group-wrapper">
                            <div class="panel-group edit-details">
                                <map-form />
                            </div>

                            <div class="add-panel-item-wrapper">
                                <h2 class="add-panel-item-header">Layers</h2>
                                <r-icon
                                    v-if="hasCopy"
                                    icon-style="fal"
                                    size="lg"
                                    name="paste"
                                    class="clickable mr-3"
                                    @click="onPasteLayer"
                                />
                                <r-label class="text-xl">Add Layer</r-label>
                                <r-icon
                                    name="plus-circle"
                                    icon-style="fal"
                                    size="lg"
                                    class="clickable ml-2"
                                    @click="toggleModal"
                                />
                            </div>
                            <div>
                                <a
                                    icon
                                    v-if="canToggleLayers"
                                    id="hide-layers"
                                    class="clickable"
                                    @click="toggleLayers"
                                    >{{ layerVisibiltyText }}</a
                                >
                            </div>
                            <div class="panel-group mt-3">
                                <layers :map="mapBox" />
                            </div>
                        </div>
                    </div>
                    <map-component v-if="workOrder" :work-order="workOrder" :selected-record="selectedRecord" />
                    <map-component v-else :user="user" :map-id="map" :selected-record="selectedRecord" />
                </main>
            </div>
        </div>

        <data-content-layer
            v-if="isDataContentVisible"
            :work-order="workOrder"
            :layer="dataContentLayer"
            @close="onCloseDataContent"
            @changed="onDataModalChanged"
        />
        <add-layer v-if="showModal" @added="onAddLayer" @close="showModal = false" @data-content="onDataContent" />
        <layer-visibility :layers="layers" :visible="layersVisible" />
        <map-import-export v-if="isSuperAdmin" @change="onMapShareChange" />
    </wrapper>
</template>

<script>
import store from 'store';
import Wrapper from 'layouts/main';
import Layers from 'components/maps/form/layers';
import AddLayer from 'components/layers/modals/addform';
import MapComponent from 'components/maps/map';
import MapsHeader from 'components/maps/header';
import MapForm from 'components/maps/form/general';
import DataContentLayer from '@/components/layers/data-content/modal.vue';
import LayerVisibility from '@/components/ui/visibility/layer-visibility';
import MapImportExport from '@/components/maps/import-export/handler.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { filter, map } from '@/utils/lodash';
import IntercomMixin from '@/mixins/intercom.vue';
import { cloneDeep, head, includes, isEmpty } from '../../utils/lodash';
export default {
    components: {
        Layers,
        MapForm,
        Wrapper,
        AddLayer,
        MapsHeader,
        MapComponent,
        MapImportExport,
        DataContentLayer,
        LayerVisibility,
    },
    mixins: [IntercomMixin],
    props: {
        user: {
            type: [String, Number],
        },
        map: {
            type: [String, Number],
        },
        workOrder: {
            type: [String, Number],
        },
    },
    data: () => ({
        loading: true,
        showModal: false,
        mapBox: null,
        isDataContentVisible: false,
        dataContentLayer: null,
        layersVisible: true,
        selectedRecord: null,
    }),
    computed: {
        ...mapGetters('auth', ['isSuperAdmin']),
        ...mapGetters('map', ['loaded', 'layers', 'title']),
        ...mapGetters('layers', ['hasCopy', 'layerContents']),
        ...mapState('records', ['dataContentRecords']),
        ...mapState('categories', ['categories']),
        canToggleLayers() {
            return this.layers.length > 0;
        },
        layerVisibiltyText() {
            return this.layersVisible ? 'Hide All' : 'Show All';
        },
    },
    watch: {
        user: {
            immediate: true,
            handler: 'setUser',
        },
        dataContentRecords: {
            deep: true,
            handler: 'onRecordsChange',
        },
    },
    metaInfo() {
        return {
            title: this.title,
        };
    },
    async created() {
        this.setPublic(false);
        this.$events.$on('map:loaded', this.onMapLoaded);
        this.$events.$on('map:marker:click', this.onMarkerClicked);
        this.$events.$on('map:deselect', this.onMapDeselect);
        // when the user clicks on the layer to edit it, this event is fired
        this.$events.$on('layer:data-content', this.onDataContent);
        this.$events.$on('layer:annotation:select', this.onLayerAnnotationSelect);
        await this.fetchMapData();
        await this.getFilteredWorkOrderCategories();
        await this.filterMapLayersBasedOnActiveCategories();
    },
    beforeDestroy() {
        this.mapBox.map.off('click');
        this.$events.$off('map:loaded', this.onMapLoaded);
        this.$events.$off('map:marker:click', this.onMarkerClicked);
        this.$events.$off('map:deselect', this.onMapDeselect);
        this.$events.$off('layer:data-content', this.onDataContent);
        this.$events.$off('layer:annotation:select', this.onLayerAnnotationSelect);
    },
    methods: {
        ...mapActions('categories', ['all', 'getWorkOrderCategories']),
        ...mapActions('layers', ['clearLayer', 'setEditing']),
        ...mapActions('map', ['setUser', 'addLayer', 'loadMap', 'setPublic']),
        ...mapMutations('categories', ['SET_CATEGORIES', 'SET_CATEGORIES_LOADED']),
        ...mapMutations('map', ['SET_LAYERS']),
        async fetchMapData() {
            await this.loadMap({
                user: this.user,
                map: this.map,
                workOrder: this.workOrder,
            });
            this.loading = false;
        },
        onPasteLayer() {
            this.addLayer(this.layerContents);
            this.clearLayer();
        },
        toggleModal() {
            this.showModal = true;
        },
        onMapLoaded(mapBox) {
            store.map = mapBox.map;
            this.mapBox = mapBox;
            this.registerEvents();
        },
        onLayerAnnotationSelect(properties, layer) {
            if (!this.selectedRecord) {
                return;
            }

            this.selectedRecord = {
                properties: { layer, ...properties },
            };
        },
        async onAddLayer(layer) {
            this.loading = true;
            try {
                let row = await this.addLayer(layer);
                this.$events.$emit('layer:added', layer, this.mapBox);
                this.$events.$emit('layer:updated', layer, this.mapBox);
                this.setEditing(row);
            } catch (e) {
                // console.error(e);
            }
            this.loading = false;
        },
        onDataContent(layer) {
            this.showModal = false;
            this.isDataContentVisible = true;
            this.dataContentLayer = layer;
        },
        onCloseDataContent() {
            this.dataContentLayer = null;
            this.isDataContentVisible = false;
            this.onRecordsChange(this.dataContentRecords);
        },
        onDataModalChanged(layer) {
            this.$events.$emit('layer:updated', layer, this.mapBox);
            this.dataContentLayer = null;
            this.isDataContentVisible = false;
        },
        async getFilteredWorkOrderCategories() {
            const allCategories = await this.all();
            if (!this.workOrder) {
                return;
            }

            let workOrderCategories = await this.getWorkOrderCategories(this.workOrder);
            workOrderCategories = map(workOrderCategories, 'category');

            const categorySlugs = map(workOrderCategories, 'slug');
            const filteredCategories = filter(allCategories, (category) => {
                return categorySlugs.includes(category.slug);
            });

            this.SET_CATEGORIES(filteredCategories);
        },
        async filterMapLayersBasedOnActiveCategories() {
            // return only the layers that are active
            // its managed from data manager; hence categories
            const otherLayers = filter(this.layers, (layer) => layer.type !== 'data_content');
            const categorySlugs = map(this.categories, 'slug');
            const dataContentLayers = filter(this.layers, (layer) => layer.type === 'data_content');
            const filteredDataContentLayers = filter(dataContentLayers, (layer) => {
                return categorySlugs.includes(layer.category);
            });
            const newlayers = [...filteredDataContentLayers, ...otherLayers];
            this.SET_LAYERS(newlayers);
        },
        toggleLayers() {
            this.layersVisible = !this.layersVisible;
        },
        registerEvents() {
            let map = this.mapBox.map;
            map.on('styleimagemissing', (e) => {
                map.loadImage(
                    'https://' + process.env.VUE_APP_RELOCITY_CDN + `/assets/map-icons/${e.id}.png`,
                    (error, image) => {
                        if (error) throw error;
                        map.addImage(e.id, image);
                    }
                );
            });
            map.on('click', (e) => {
                let features = filter(
                    map.queryRenderedFeatures([
                        [e.point.x - 15, e.point.y - 15],
                        [e.point.x + 15, e.point.y + 15],
                    ]),
                    (feature) => {
                        return (
                            includes(feature.layer.id, 'SCHOOLS') ||
                            includes(feature.layer.id, 'AREA_TOUR') ||
                            includes(feature.layer.id, 'CUSTOM') ||
                            includes(feature.layer.id, 'COMMUTE_RADIUS') ||
                            includes(feature.layer.id, 'DESTINATION') ||
                            includes(feature.layer.id, 'DATA_CONTENT')
                        );
                    }
                );

                let feature = head(features);

                if (!isEmpty(feature)) {
                    if (includes(feature.layer.id, 'COMMUTE_RADIUS')) {
                        this.$events.$emit('map:polygon:click', feature, e.lngLat);
                        this.$events.$emit('map:deselect');
                    } else if (includes(feature.layer.id, 'DESTINATION')) {
                        this.$events.$emit('map:destination:click', feature, e.lngLat);
                    } else {
                        this.$events.$emit('map:marker:click', feature);
                    }
                } else {
                    this.$events.$emit('map:deselect');
                }
            });
        },
        onMarkerClicked(feature) {
            if (isEmpty(feature.properties)) {
                return;
            }

            if (feature.properties.cluster) {
                return;
            }
            this.selectedRecord = feature;
            let displayDetails = null;
            try {
                displayDetails = JSON.parse(this.selectedRecord.properties?.display_details);
            } catch (e) {
                displayDetails = this.selectedRecord.properties?.display_details;
            }
            this.$events.$emit('map:popup:enter', {
                coordinates: this.selectedRecord.geometry.coordinates,
                html: displayDetails?.name ?? this.selectedRecord.properties.name,
                options: {
                    offset: [0, -35],
                },
            });
        },
        onMapDeselect() {
            this.selectedRecord = null;
            this.$events.$emit('map:popup:leave');
        },
        async onMapShareChange() {
            await this.fetchMapData();
        },
        onRecordsChange(newData) {
            if (!this.selectedRecord?.properties) {
                return;
            }
            const updatedRecord = cloneDeep(this.selectedRecord);
            let layerData = null;
            try {
                layerData = JSON.parse(updatedRecord.properties.layer);
            } catch (e) {
                layerData = updatedRecord.properties.layer;
            }

            if (!newData[layerData.id]) {
                return;
            }

            const features = newData[layerData.id]?.features ?? newData[layerData.id].geoJson?.features;

            // Get the properties of the matching selectedRecord
            const matchingRecord = filter(features, (feature) => feature.id === updatedRecord.properties.record_id)[0];

            // Update appropriate values
            updatedRecord.properties.is_recommended = matchingRecord.properties.is_recommended;
            if (matchingRecord.properties.platform_id) {
                updatedRecord.properties.platform_id = matchingRecord.properties.platform_id;
            } else {
                delete updatedRecord.properties.platform_id;
            }

            this.selectedRecord = updatedRecord;
        },
    },
};
</script>

<style lang="scss" scoped>
#hide-layers {
    font-family: Avenir;
    font-style: italic;
    font-weight: 500;
    color: #9b9b9b;
}

.clickable {
    cursor: pointer;

    &:hover {
        color: #9b9b9b;
    }
}
</style>
