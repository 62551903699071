<template>
    <div class="flex flex-col space-y-2">
        <div class="flex flex-row space-x-2 items-center">
            <h5 class="text-primary text-md font-bold">{{ field.title }}</h5>
            <field-actions :field="field" :layer="layer" v-on="$listeners" />
        </div>
        <field-switch
            v-for="option in field.options"
            :key="option.value"
            :field="field"
            :option="option"
            :disabled="isLocked"
            :value="isSelected(option)"
            @change="onChange"
        />
    </div>
</template>

<script>
import { includes, filter } from '@/utils/lodash';
import FieldSwitch from './switch-group-switch.vue';
import FieldActions from '../actions.vue';
export default {
    components: {
        FieldSwitch,
        FieldActions,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
        layer: {
            type: Object,
            default: () => {},
        },
        value: {
            type: [Array, String, Boolean],
        },
    },
    computed: {
        isLocked() {
            return includes(this.layer.filters?.locked_fields, this.field.id);
        },
        valueAsArray() {
            if (typeof this.value === 'string') {
                return this.value.split(',');
            }

            return this.value;
        },
    },
    methods: {
        onChange(value, option) {
            let values = this.value || [];

            // if value is a string split it
            if (typeof values === 'string') {
                values = values.split(',');
            }

            if (value) {
                values.push(option.value);
            } else {
                values = filter(values, (i) => i !== option.value);
            }

            this.$emit('change', values);
        },
        isSelected(option) {
            return includes(this.valueAsArray, option.value);
        },
    },
};
</script>
