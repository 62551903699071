<template>
    <div class="flex flex-col space-y-4">
        <layer-form v-bind="{ ...$props, ...$attrs }" v-on="$listeners" />
        <layer-filters v-bind="{ ...$props, ...$attrs }" v-on="$listeners" />
    </div>
</template>

<script>
import LayerForm from './tabs/layer.vue';
import LayerFilters from './tabs/filters.vue';
export default {
    components: {
        LayerForm,
        LayerFilters,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        category: {
            type: Object,
            required: true,
        },
        filters: {
            type: [Object, Array],
            required: true,
        },
        providers: {
            type: Array,
            required: true,
        },
    },
};
</script>
