<template>
    <div class="flex justify-between items-center space-y-2">
        <label for="geometry-lock" class="form__label text-primary">Lock Geometry for Client</label>
        <r-switch
            v-model="locked"
            id="geometry-lock"
            name="geometry-lock"
            icon
            @click="onSelect"
            @keydown.enter.prevent="onSelect"
            @keydown.space.prevent="onSelect"
        >
            <template #iconOn>
                <r-icon icon-style="fas" variant="transparent" name="lock" size="xs" />
            </template>
            <template #iconOff>
                <r-icon icon-style="fas" variant="transparent" name="lock-open" size="xs" />
            </template>
        </r-switch>
    </div>
</template>

<script>
export default {
    computed: {
        locked: {
            get() {
                return this.$attrs.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        onSelect(value) {
            this.$emit('input', value);
        },
    },
};
</script>
