<template>
    <div class="title-and-caption">
        <h2 class="title">{{ media.asset.title }}</h2>
        <p v-if="!hideCaption" class="caption">{{ media.asset.caption }}</p>
    </div>
</template>

<script>
export default {
    props: {
        media: {
            type: Object,
            required: true,
        },
        hideCaption: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.title-and-caption {
    width: 100%;
    padding: 20px;
    text-align: center;
    background-color: rgba(20, 20, 20, 0.3);
    position: absolute;
    bottom: 5px;
    left: 0;
    border-radius: 0 0 5px 5px;

    .title {
        font-family: Avenir-light;
        font-size: 33px;
        font-weight: 500;
        color: white;
    }

    .caption {
        font-family: Avenir-light;
        font-size: 18px;
        color: white;
    }
}
</style>
