<template>
    <div>
        <div class="title">
            <r-editable
                v-if="!readonly"
                v-model="title"
                default="Enter a title..."
                :readonly="readonly"
                @change="onTitleChange"
                @close="onClose"
            />
        </div>
        <div class="caption">
            <r-editable
                v-if="title"
                v-model="caption"
                type="textarea"
                class="caption-edit-textarea"
                :default="captionPlaceholder"
                :readonly="readonly"
                @change="onCaptionChange"
                @close="onClose"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'No Title',
            twoWay: true,
        },
        caption: {
            type: String,
            default: '',
        },
        captionPlaceholder: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onTitleChange(value) {
            if (value.bubbles) {
                return;
            }
            this.$emit('change', { title: value });
        },
        onClose() {
            this.$emit('close');
        },
        onCaptionChange(value) {
            // this gets fired with an Event object as well
            if (typeof value !== 'string') {
                return;
            }

            this.$emit('change', { caption: value });
        },
    },
};
</script>

<style>
.caption-edit-textarea textarea {
    height: 30px;
}
</style>
