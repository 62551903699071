<template>
    <div>
        <h3 class="text-lg leading-6 text-primary mb-3">Layer Details</h3>
        <r-input-group label="Layer Name" class="mb-6">
            <r-input id="name" name="name" placeholder="Enter a layer name" v-model="name" />
        </r-input-group>
        <r-input-group v-if="hasProviders && !hasWorkOrder" label="Restrict to Providers" class="mb-6 tags-input">
            <providers-dropdown v-model="providersList" :providers="category.providers" />
        </r-input-group>
        <icon-selector v-model="icon" />
        <geometry-lock v-if="isFeatureEnabled" v-model="lockedMap" />
    </div>
</template>

<script>
import { get, map, isEmpty } from 'utils/lodash';
import IconSelector from 'components/ui/icon-selector/select.vue';
import ProvidersDropdown from 'components/providers/dropdown.vue';
import GeometryLock from './geometry-lock.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        GeometryLock,
        IconSelector,
        ProvidersDropdown,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        category: {
            type: Object,
            required: true,
        },
        filters: {
            type: [Object, Array],
            required: true,
        },
        providers: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters('map', ['workOrder']),
        isFeatureEnabled() {
            return process.env.VUE_APP_FEATURE_ENABLE_LOCK_HIDE === 'true';
        },
        icon: {
            get() {
                return get(this.layer, 'icon', this.category.icon ?? 'relocity-custom-marker');
            },
            set(value) {
                this.onChange('icon', value);
            },
        },
        name: {
            get() {
                return this.layer.name;
            },
            set(value) {
                this.onChange('name', value);
            },
        },
        providersList: {
            get() {
                return this.providers;
            },
            set(value) {
                this.onChange('providers', map(value, 'id'));
            },
        },
        lockedMap: {
            get() {
                return get(this.layer, 'filters.locked_map', false);
            },
            set(value) {
                this.$emit('lock-map', value);
            },
        },
        hasProviders() {
            return !isEmpty(this.category.providers);
        },
        hasWorkOrder() {
            return !isEmpty(this.workOrder);
        },
    },
    methods: {
        onChange(key, value) {
            this.$emit('change', key, value);
        },
    },
};
</script>
