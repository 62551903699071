<template>
    <div v-text="summary" />
</template>

<script>
import { get } from 'utils/lodash';
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
    },
    computed: {
        filters() {
            return get(this.layer, 'filters');
        },
        radius() {
            return get(this.filters, 'radius', 0);
        },
        summary() {
            return `${this.radius} Mile Radius`;
        },
    },
};
</script>
