<template>
    <div v-if="isSupported" class="video-modal-wrapper">
        <r-icon class="close-btn" name="times-circle" icon-style="fad" color="#aaa" size="lg" @click.stop="onClose" />
        <i v-if="!playing" class="fas fa-play-circle play-btn" @click="startVideo"></i>
        <video ref="video_preview" id="video-preview" class="center" @click="pauseVideo" />
    </div>
    <div v-else class="not-supported video-modal-wrapper">
        <div>
            <r-icon
                class="close-btn"
                name="times-circle"
                icon-style="fad"
                color="#aaa"
                size="lg"
                @click.stop="onClose"
            />
            <r-icon name="frown" size="4x" color="#ff7f7e" />
            <p>Your browser doesn’t support video playback of this video:</p>
            <p>
                <a :href="url" target="_blank">{{ url }}</a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        hls: null,
        playing: false,
    }),
    props: {
        media: {
            type: Object,
            required: true,
        },
    },
    watch: {
        media() {
            this.$nextTick(this.loadVideo);
        },
        playing(isPlaying) {
            this.$emit('playchange', isPlaying);
        },
    },
    computed: {
        url() {
            return this.media.asset.url;
        },
        isSupported() {
            return Hls.isSupported() && this.url;
        },
        player() {
            return this.hls?.media;
        },
    },
    mounted() {
        this.loadVideo();
    },
    methods: {
        onClose() {
            this.$emit('close');
        },
        loadVideo() {
            // unload old stream
            if (this.hls != null) {
                this.hls.destroy();
            }

            if (this.isSupported) {
                let video = this.$refs.video_preview;
                this.hls = new Hls();
                this.hls.loadSource(this.url);
                this.hls.attachMedia(video);

                this.player.addEventListener('ended', () => {
                    this.playing = false;
                });
            }
        },
        startVideo() {
            this.playing = true;
            this.player.play();
        },
        pauseVideo() {
            if (!this.playing) {
                return;
            }

            this.playing = false;
            this.player.pause();
        },
    },
};
</script>

<style lang="scss" scoped>
.video-modal-wrapper {
    height: 100%;
    position: relative;
    width: auto;
    display: inline-block;

    .close-btn {
        position: absolute;
        margin-top: -23px;
    }

    .play-btn {
        color: white;
        font-size: 68px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        z-index: 1;
        &:hover {
            color: #eee;
        }
    }

    video#video-preview {
        object-fit: contain;
        width: 100%;
        height: 100%;
        background: black;

        &.center {
            width: 100%;
            margin: 0;
        }
    }

    &.not-supported {
        display: flex;
        align-items: center;
        min-height: 450px;

        & > div {
            word-break: break-word;
        }

        p {
            line-height: 2em;
        }

        a {
            font-family: Avenir;
            font-size: 13px;
            text-decoration: underline;
            color: #141414;
        }
    }
}
</style>
