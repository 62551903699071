<template>
    <div :class="{ active: active }" @click="onClick" v-text="title" />
</template>

<script>
import { startCase } from 'utils/lodash';
export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        layer: {
            type: Object,
            required: true,
        },
    },
    computed: {
        title() {
            return startCase(this.type);
        },
        active() {
            return this.layer.filters.profile == this.type;
        },
    },
    methods: {
        onClick() {
            this.$emit('change', this.type);
        },
    },
};
</script>
