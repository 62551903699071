<template>
    <div class="menu-icon-wrapper">
        <button class="menu-icon" />
        <div class="sub-menu">
            <div class="sub-menu-option" @click="onDeleteMedia">Delete</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        asset: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        onDeleteMedia() {
            this.$emit('delete', this.asset);
        },
    },
};
</script>
