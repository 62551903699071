<template>
    <div class="position" :style="styles">
        <span>{{ position }}</span>
    </div>
</template>

<script>
import { icon } from 'utils/helpers';
export default {
    props: {
        icon: {
            type: String,
            required: true,
        },
        position: {
            type: [String, Number],
            required: true,
        },
        large: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        styles() {
            return {
                'background-image': `url(${icon(this.icon)})`,
                width: this.large ? '44px' : '40px',
                height: this.large ? '44px' : '32px',
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.position {
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-size: contain;

    span {
        color: #fff;
    }
}
</style>
