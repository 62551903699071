import http from 'services/axios';
class Place {
    static get(placeId) {
        return http.get(
            process.env.VUE_APP_RELOCITY_DATA_CONTENT_URL +
                '/api/v1/place/details?api_key=' +
                process.env.VUE_APP_RELOCITY_DATA_CONTENT_API_KEY +
                '&place_id=' +
                placeId
        );
    }

    static autoCompleteUrl(input, latitude, longitude, radius, locationbias = '') {
        return (
            process.env.VUE_APP_RELOCITY_DATA_CONTENT_URL +
            '/api/v1/place/autocomplete?api_key=' +
            process.env.VUE_APP_RELOCITY_DATA_CONTENT_API_KEY +
            '&input=' +
            input +
            '&latitude=' +
            latitude +
            '&longitude=' +
            longitude +
            '&radius=' +
            radius +
            '&locationbias=' +
            locationbias +
            '&strictbounds=1'
        );
    }
}

export default Place;
