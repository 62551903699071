<template>
    <div class="thumb" @click="onClick">
        <div v-if="isVideo && isAssetReady" class="centered">
            <r-icon color="#fff" name="play-circle" size="2x" />
        </div>

        <div class="thumb-img">
            <img v-if="isAssetReady" :class="classes" :src="imageSource" :alt="altText" />
            <div v-else class="centered">
                <span v-if="!isDoneUploading" class="processing">{{ statusTitle }}</span>
            </div>
        </div>

        <div>
            <div v-if="isUploading" class="centered" v-text="progressPercent" />
            <div v-else-if="isDone" class="centered">
                <r-icon class="done" name="check" />
            </div>
        </div>
    </div>
</template>

<script>
import { startsWith, startCase } from 'utils/lodash';
export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        thumb: {
            type: [String, Boolean],
            default: null,
        },
        progress: {
            type: [String, Number],
            default: null,
        },
        status: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        complete: false,
    }),
    computed: {
        classes() {
            return [
                'thumb-img',
                this.isVideo ? 'video' : '',
                this.isUploading ? 'uploading' : '',
                this.isDone ? 'done' : '',
            ];
        },
        statusTitle() {
            return startCase(
                this.progress == 100 || this.status == 'uploaded' || this.status == 'uploading' ? '' : this.status
            );
        },
        isAssetReady() {
            return this.status === 'ready' && this.thumb;
        },
        isImage() {
            return startsWith(this.type, 'image') || this.type === 'photo';
        },
        isVideo() {
            return startsWith(this.type, 'video');
        },
        imageSource() {
            return this.thumb;
        },
        isDoneUploading() {
            return this.progress == 100 || this.status == 'uploaded';
        },
        isUploading() {
            return this.progress !== null && this.progress < 100;
        },
        isDone() {
            return this.progress == 100 && !this.complete;
        },
        progressPercent() {
            if (!this.progress) {
                return null;
            }
            return this.progress.toFixed() + '%';
        },
        altText() {
            return this.type + '-thumb';
        },
    },
    watch: {
        progress() {
            if (this.progress >= 100) {
                setTimeout(() => {
                    this.complete = true;
                }, 3000);
            }
        },
    },
    methods: {
        onClick() {
            this.$emit('click', this.$props);
        },
    },
};
</script>
