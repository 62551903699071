<template>
    <r-select
        v-model="provider"
        :options="tempHousingProviders"
        track-by="id"
        label="name"
        id="temp-housing-provider-type"
        name="temp-housing-provider-type"
        classes="temp-housing-provider-type"
        empty
        @change="onChange"
    />
</template>

<script>
import { isEmpty } from 'utils/lodash';
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {
        provider: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters('tempHousingProviders', ['tempHousingProviders']),
    },
    created() {
        if (isEmpty(this.tempHousingProviders)) {
            this.getTempHousingProviders();
        }
    },
    methods: {
        ...mapActions('tempHousingProviders', ['getTempHousingProviders']),
        onChange(value) {
            this.$emit('change', value);
        },
    },
};
</script>
