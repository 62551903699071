<template>
    <span />
</template>

<script>
import { modifyFeatures } from 'utils/helpers';
import { get } from 'utils/lodash';
import MapService from 'services/mapservice';
import { mapGetters, mapState } from 'vuex';
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState('records', ['dataContentRecords']),
        ...mapGetters('map', ['isChangingProvider']),
        records() {
            return this.dataContentRecords[this.layer.id];
        },
        icon() {
            return get(this.layer, 'icon', 'relocity-custom-marker');
        },
        layerId() {
            return this.layer.name + '__DATA_CONTENT__';
        },
        sourceId() {
            return this.layer.name + '__DATA_CONTENT_SOURCE__';
        },
        jsonSource() {
            return {
                ...this.records,
                features: modifyFeatures(this.records?.features, {
                    layerType: 'data_content',
                    layer: this.layer,
                }),
            };
        },
    },
    watch: {
        records: {
            deep: true,
            handler: 'checkCategoryRecordsAvailable',
        },
    },
    created() {
        this.checkCategoryRecordsAvailable();
    },
    beforeDestroy() {
        if (this.isChangingProvider) {
            return;
        }

        this.removeLayer();
    },
    methods: {
        async checkCategoryRecordsAvailable() {
            // ensure layer is created
            if (!MapService.sourceIsLoaded(this.sourceId)) {
                await this.createLayer();
                return;
            }

            // update layer
            // this.createLayer();
            this.onJsonSourceChange();
        },
        onJsonSourceChange() {
            MapService.updateMarkerCircleLayerIcon(this.layerId, this.icon);
            MapService.updateClusterCircleLayerIcon(this.layerId, this.layer.icon);
            MapService.updateSource(this.sourceId, this.jsonSource);
        },
        async createLayer() {
            await MapService.addClusteredFeatureCollection(this.sourceId, this.jsonSource);
            MapService.addClusterCircleLayer(this.sourceId, this.layerId, this.icon);
            MapService.addClusterCircleTextLayer(this.sourceId, this.layerId);
            MapService.addMarkerCircleLayer(this.sourceId, this.layerId, this.icon);
            MapService.addMarkerCircleRecommendedIconLayer(this.sourceId, this.layerId, this.icon);
            MapService.addMarkerCircleFavoriteIconLayer(this.sourceId, this.layerId, this.icon);
        },
        async removeLayer() {
            await MapService.removeMarkerCircleLayer(this.layerId);
            await MapService.removeClusterCircleTextLayer(this.layerId);
            await MapService.removeClusterCircleLayer(this.layerId);
            await MapService.removeMarkerCircleRecommendedIconLayer(this.layerId);
            await MapService.removeMarkerCircleFavoriteIconLayer(this.layerId);
            await MapService.removeSource(this.sourceId);
        },
    },
};
</script>
