<template>
    <r-modal open title="Add Layer" @close="onCloseModal">
        <form @submit.prevent="onSave">
            <r-input-group label="Name" class="mb-6">
                <r-input
                    v-focus
                    id="name"
                    name="name"
                    placeholder="Enter a layer name"
                    v-model="name"
                    :classes="{ invalid: inputIsInvalid }"
                />
            </r-input-group>
            <r-input-group label="Type" class="square-select mb-6">
                <r-select
                    v-model="type"
                    :options="defaultLayerTypes"
                    option-group-label="label"
                    option-group-children="items"
                    id="layer-type"
                    name="layer-type"
                    @change="(value) => (type = value)"
                />
            </r-input-group>
            <div v-if="isTempHousing">
                <r-input-group label="Type" class="square-select">
                    <temp-housing-dropdown
                        :providers="tempHousingProviders"
                        :provider="provider"
                        @change="(value) => (provider = value)"
                    />
                </r-input-group>
            </div>
            <r-button @click="onSave">Save</r-button>
        </form>
    </r-modal>
</template>

<script>
import { get, map, includes, replace, filter } from 'utils/lodash';
import { mapGetters, mapState } from 'vuex';
import TempHousingDropdown from 'components/layers/temphousing/dropdown';
export default {
    components: {
        TempHousingDropdown,
    },
    data: () => ({
        inputIsInvalid: false,
        name: null,
        type: 'custom',
        provider: '',
    }),
    computed: {
        ...mapGetters('map', ['config']),
        ...mapState('categories', ['categories']),
        ...mapGetters('layers', ['types', 'getConfig']),
        ...mapGetters('map', ['hasWorkOrder', 'workOrderId']),
        ...mapGetters('categories', ['findCategory', 'findDataContentCategoryById', 'dataContentCategoriesList']),
        ...mapGetters('providers', ['tempHousingProviders']),
        isTempHousing() {
            return this.type == 'temp_housing';
        },
        dataContentCategoriesItems() {
            const activeCategorySlugs = map(this.categories, (category) =>
                category.maps_toggle === true ? category.slug : ''
            );
            let categories = this.categories;
            if (this.workOrderId) {
                categories = this.dataContentCategoriesList;
            }

            // return only the categories allowed to be listed in the dropdown
            return filter(categories, (category) => {
                return activeCategorySlugs.includes(category.slug);
            });
        },
        defaultLayerTypes() {
            return [
                {
                    label: 'Layers',
                    code: 'fixed',
                    items: this.types,
                },
                ...this.dataContentCategories,
            ];
        },
        dataContentCategories() {
            return [
                {
                    label: 'Data Content',
                    code: 'data-content',
                    items: map(this.dataContentCategoriesItems, (category) => {
                        return {
                            key: `data-content-${category.id}`,
                            title: category.title,
                        };
                    }),
                },
            ];
        },
        isDataContentType() {
            return includes(this.type, 'data-content-');
        },
    },
    methods: {
        onCloseModal() {
            this.$emit('close');
        },
        getCategory(categoryType) {
            let category = null;

            if (this.workOrderId) {
                let match = this.findDataContentCategoryById(categoryType);

                if (match) {
                    category = match.category;
                }
            } else {
                category = this.findCategory(categoryType);
            }

            return category;
        },
        onDataContent() {
            const categoryType = replace(this.type, 'data-content-', '');
            const category = this.getCategory(categoryType);

            if (!category) {
                this.$error('Category was not found.');
                return;
            }

            const newDataContentLayer = {
                id: null,
                name: this.name,
                category: category.slug,
                filters: {
                    fields: {},
                    geometry: null,
                },
            };

            return this.$emit('data-content', newDataContentLayer);
        },
        onSave() {
            if (!this.name) {
                this.inputIsInvalid = true;

                return;
            }

            // If this is a data content then pass onto next modal
            if (this.isDataContentType) {
                return this.onDataContent();
            }

            let layerConfig = this.getConfig(this.type);

            layerConfig.isNew = true;
            layerConfig.name = this.name;
            layerConfig.type = this.type;
            layerConfig.provider_id = this.provider;
            if (this.isTempHousing) {
                layerConfig.filters.reference_number = get(this.config, 'work_order.reference_number');
            }

            this.$emit('added', layerConfig);
            this.onCloseModal();
        },
    },
};
</script>
