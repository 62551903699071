<template>
    <div class="flex flex-col">
        <textarea
            v-model="json"
            placeholder="Paste JSON Layers here"
            class="w-full h-80 border border-primary rounded-lg p-2"
        />

        <div class="flex flex-row justify-end mt-4">
            <r-button square large :disabled="!hasContent" @click="onSave">Save</r-button>
        </div>
    </div>
</template>

<script>
import Map from 'models/Map';
import { mapGetters } from 'vuex';
import { isEmpty } from '@/utils/lodash';
export default {
    data: () => ({
        json: null,
    }),
    computed: {
        ...mapGetters('map', ['user', 'id']),
        validJson() {
            try {
                return JSON.parse(this.json);
            } catch {
                return false;
            }
        },
        hasContent() {
            return !isEmpty(this.validJson);
        },
    },
    methods: {
        async onSave() {
            try {
                await Map.import(this.user, this.id, { json: this.json });
                this.$emit('imported');
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>
