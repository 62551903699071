<template>
    <div class="layers-panel">
        <r-loading-bounce v-if="loading" />
        <r-loading-spinner :visible="layersLoading">
            <div v-if="map">
                <r-draggable v-model="layers" @change="onChangeLayers">
                    <layer
                        v-for="layer in layers"
                        :key="layer.id"
                        :layer="layer"
                        :map="map"
                        sortable
                        @rename="onRename"
                        @delete="onDelete"
                        @editing="onEditingLayer"
                    />
                </r-draggable>
            </div>
        </r-loading-spinner>

        <rename-modal
            v-if="isRenameModalVisible"
            :layer="selectedLayer"
            @renamed="onRenamed"
            @close="isRenameModalVisible = false"
        />

        <delete-confirmation-modal
            v-if="showDeleteConfirmationModal"
            open
            title="Delete layer"
            confirm-button-text="Yes, Delete"
            @cancel="showDeleteConfirmationModal = false"
            @confirm="onDeleteLayerConfirm"
        >
            Are you sure you want to delete this layer?
        </delete-confirmation-modal>
    </div>
</template>

<script>
import { isEmpty } from 'utils/lodash';
import { mapGetters, mapActions } from 'vuex';
import Layer from 'components/layers/form/layer';
import RenameModal from 'components/layers/modals/rename';
import DeleteConfirmationModal from 'components/ui/modal/delete-confirmation-modal';
export default {
    components: {
        Layer,
        RenameModal,
        DeleteConfirmationModal,
    },
    props: {
        map: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        sortable: true,
        loading: false,
        layersLoading: true,
        selectedLayer: {},
        isRenameModalVisible: false,
        showDeleteConfirmationModal: false,
        layerToDelete: {},
    }),
    computed: {
        ...mapGetters('map', ['layers']),
    },
    watch: {
        map() {
            if (!isEmpty(this.map)) {
                this.layersLoading = false;
            }
        },
    },
    methods: {
        ...mapActions('map', ['removeLayer', 'updateMap', 'clearLayers', 'reorderLayers']),
        onRename(layer) {
            this.selectedLayer = layer;
            this.isRenameModalVisible = true;
        },
        onRenamed() {
            this.selectedLayer = {};
            this.isRenameModalVisible = false;
        },
        onEditingLayer(editing) {
            this.sortable = !editing;
        },
        onDelete(layer) {
            this.showDeleteConfirmationModal = true;
            this.layerToDelete = layer;
        },
        async onDeleteLayerConfirm() {
            this.loading = true;

            try {
                await this.removeLayer(this.layerToDelete);
            } catch (e) {
                this.$error('Failed Deleting Layer.');
            }

            this.layerToDelete = {};
            this.showDeleteConfirmationModal = false;
            this.loading = false;
        },
        async onChangeLayers(layers) {
            const layerIds = layers.map((layer) => layer.id);

            await this.reorderLayers(layerIds);
        },
    },
};
</script>
