<template>
    <div class="grid grid-flow-col grid-cols-2 gap-x-10">
        <r-input-group>
            <template #label>
                <slot name="label">
                    <div class="flex flex-row space-x-2 justify-center items-center">
                        <label :label="field.title" :for="field.slug" class="form__label text-primary">{{
                            field.title
                        }}</label>
                        <field-actions :field="field" :layer="layer" v-on="$listeners" />
                    </div>
                </slot>
            </template>
            <r-datetime-picker v-model="fromValue" @input="onFromChange" @clear="onClearFrom" />
        </r-input-group>
        <r-input-group class="mt-5">
            <r-datetime-picker v-model="toValue" @input="onToChange" @clear="onClearTo" />
        </r-input-group>
    </div>
</template>

<script>
import { isEmpty, get } from '@/utils/lodash';
import FieldActions from '../actions.vue';
export default {
    components: {
        FieldActions,
    },
    props: {
        layer: {
            type: Object,
            default: () => {},
        },
        field: {
            type: Object,
            required: true,
        },
        value: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        fromValue: '',
        toValue: '',
    }),
    computed: {
        type() {
            return get(this.field, 'extra.date_range_type');
        },
    },
    watch: {
        value: {
            immediate: true,
            handler: 'onValueChange',
        },
    },
    methods: {
        onValueChange() {
            if (isEmpty(this.value)) {
                return;
            }

            const [min, max] = this.value.split(',');
            this.fromValue = min;
            this.toValue = max;
        },
        onFromChange(val) {
            this.fromValue = val;
            this.onChange();
        },
        onToChange(val) {
            this.toValue = val;
            this.onChange();
        },
        onChange() {
            let range = [this.fromValue, this.toValue, this.type];

            if (isEmpty(this.fromValue) && isEmpty(this.toValue)) {
                this.$emit('change', '');
            } else {
                this.$emit('change', range.join(','));
            }
        },
        onClearFrom() {
            this.fromValue = '';
            this.onChange();
        },
        onClearTo() {
            this.toValue = '';
            this.onChange();
        },
        onToggleLock(field, value) {
            this.$emit('lock', field, value);
        },
        onToggleVisibility(field, value) {
            this.$emit('hide', field, value);
        },
    },
};
</script>

<style>
.vdatetime-calendar__month__day--selected > span > span,
.vdatetime-calendar__month__day--selected > span > span:hover,
.vdatetime-popup__header {
    background-color: #000;
}
.vdatetime-time-picker__item--selected,
.vdatetime-popup__actions__button {
    color: #000;
}
</style>
