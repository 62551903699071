<template>
    <div class="custom-icon-input-container">
        <icon-image :icon="icon" />
        <r-input-group label="Icon" class="square-select">
            <r-select
                id="custom-icon-input"
                name="custom-icon-input"
                v-bind="$attrs"
                track-by="value"
                label="name"
                :options="icons"
                @change="onChange"
            />
        </r-input-group>
    </div>
</template>

<script>
import ICONS from 'utils/custom-layer-icons';
import IconImage from './image.vue';
export default {
    components: {
        IconImage,
    },
    data: () => ({
        icons: ICONS,
    }),
    computed: {
        icon: {
            get() {
                return this.$attrs.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        onChange(value) {
            this.$emit('input', value);
        },
    },
};
</script>
