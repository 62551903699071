<template>
    <div class="gallery-container">
        <div class="gallery-header">
            <div class="gallery-title">Photo &amp; Video Gallery</div>
            <div class="menu-icon-wrapper">
                <button class="menu-icon" />
                <div class="sub-menu left">
                    <button class="sub-menu-option" @click="onAddAssets">Manage</button>
                    <button class="sub-menu-option" @click="onDeleteGallery">Delete</button>
                </div>
            </div>
        </div>
        <div class="gallery-placeholder">
            <medias v-if="hasGallery && hasAssets" :gallery="gallery" @click="onMediaClick" />
            <div v-else-if="hasGallery && galleryLoading">
                <r-loading-spinner :visible="galleryLoading"></r-loading-spinner>
            </div>
            <div v-else class="empty" @click="onAddAssets">
                Add Photo &amp; Video Gallery
                <div class="add-button">
                    <r-icon name="plus-circle" icon-style="fas" @click="onAddAssets" />
                </div>
            </div>
        </div>

        <portal to="root-portal-target">
            <gallery-modal
                v-if="isModalVisible"
                :index="index"
                :layer="layer"
                :gallery="gallery"
                @completed="onCompleted"
                @sort="onSort"
                @changed="onChanged"
                @uploaded="onUploaded"
                @close="isModalVisible = false"
                @click="onMediaThumbClick"
                @file-change="onFileChange"
            />

            <media-modal v-if="isMediaModalVisible" :media="media" @close="isMediaModalVisible = false" />
        </portal>
    </div>
</template>

<script>
import Medias from './medias';
import { get, isEmpty, findIndex, debounce } from 'utils/lodash';
import GalleryModal from 'components/gallery/modal';
import MediaModal from 'components/gallery/asset/modal';
import { mapActions, mapGetters } from 'vuex';
import Echo from 'services/echo';
export default {
    components: {
        Medias,
        MediaModal,
        GalleryModal,
    },
    props: {
        index: {
            type: [String, Number],
            default: null,
        },
        layer: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        gallery: { media: [] },
        galleryLoading: true,
        isModalVisible: false,
        isMediaModalVisible: false,
        media: null,
        isListening: false,
    }),
    computed: {
        ...mapGetters('map', ['id']),
        annotation() {
            return get(this.layer.annotations, this.index);
        },
        hasGallery() {
            return get(this.annotation, 'gallery.id') !== undefined;
        },
        hasAssets() {
            return this.hasGallery && !isEmpty(get(this.gallery, 'media'));
        },
    },
    watch: {
        ['annotation.gallery']: {
            deep: true,
            immediate: true,
            handler: 'fetchGallery',
        },
        ['gallery.gallery_url']() {
            this.listen();
        },
    },
    beforeDestroy() {
        if (this.isListening && this.hasGallery) {
            Echo.private(this.gallery.broadcast_channel).stopListening('.media.created');
        }
    },
    methods: {
        ...mapActions('gallery', ['get']),
        ...mapActions('map', ['updateAnnotation']),
        ...mapActions('gallery', ['flagToDelete']),
        onAddAssets() {
            this.isModalVisible = true;
        },
        onDeleteGallery() {
            this.flagToDelete({
                gallery: this.gallery.id,
                map: this.id,
            });
            this.gallery = {};

            this.updateAnnotation({
                layer: this.layer,
                index: this.index,
                annotation: { ...this.annotation, gallery: {} },
            });
        },
        fetchGallery: debounce(async function () {
            if (!this.hasGallery) {
                return;
            }

            this.galleryLoading = true;

            this.gallery = await this.get({
                gallery: this.annotation.gallery.id,
            }).finally(() => {
                this.galleryLoading = false;
            });
        }, 1000),
        listen() {
            if (!this.isListening && this.hasGallery) {
                this.isListening = true;
                Echo.private(this.gallery.broadcast_channel).listen('.media.created', (asset) =>
                    this.gallery.media.push(asset)
                );
            }
        },
        onCompleted() {
            this.fetchGallery();
        },
        onChanged() {
            this.fetchGallery();
        },
        onUploaded() {
            this.fetchGallery();
        },
        onSort(assets) {
            this.gallery.media = assets;
        },
        onMediaClick() {
            this.isModalVisible = true;
        },
        onMediaThumbClick(media) {
            this.isMediaModalVisible = true;
            this.media = media;
        },
        onFileChange(file) {
            let mediaIndex = findIndex(this.gallery.media, (media) => media.id == file.id);

            if (mediaIndex === -1) {
                this.gallery.media.push(file);
            } else {
                this.gallery.media[mediaIndex] = file;
                this.$set(this.gallery.media, mediaIndex, file);
            }
        },
    },
};
</script>

<style lang="scss">
@import 'scss/components/_gallery';
</style>
